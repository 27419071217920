<div *ngIf="isMobile$ | async as isMobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">
  <mat-icon mat-dialog-close>close</mat-icon>
  <h2 mat-dialog-title>{{ 'PROFILE.MFA.HEADER' | translate }}</h2>
  <!-- <p>{{ 'PROFILE.MFA.BODY' | translate }}</p> -->

  <mat-error style="text-align: center;" *ngIf="mfaRequestResult.error">{{ labels[mfaRequestResult.code] | translate
    }}</mat-error>

  <mat-dialog-content class="mat-typography" fxLayout="column" fxLayoutGap="35px" fxLayoutAlign="center center"
    class="earnr-code" [ngClass]="{
      mobile: isMobile.mobile }">
    <app-submit-button label="" (onClick)="requestCode()" [isLoading]="fetchingCode" tabindex="1" [ngClass]="{
      mobile: isMobile.mobile }">
      {{ (codeRequested ? labels.requestAnotherCodeButton : labels.requestCodeButton) | translate }}
    </app-submit-button>

    <mat-form-field appearance="outline" [ngClass]="{ mobile: isMobile.mobile }">
      <mat-label>{{ 'PROFILE.MFA.FORM.EARNR_CODE' | translate }}</mat-label>
      <input matInput type="text" name="verificationCode" tabindex="2" [formControl]="earnrCodeControl" maxlength="6"
        minlength="6">
      <!-- <mat-error *ngIf="emailControl.invalid">{{ getErrorMessages(emailControl) }}</mat-error> -->
    </mat-form-field>


    <button mat-stroked-button (click)="submitToken()" [disabled]="!codeRequested"
      [ngClass]="{ mobile: isMobile.mobile }">Submit</button>


  </mat-dialog-content>

</div>