/**
 * Currently not used. We use the GUARD to fetch the template or redirect to a Page Not Found page
 */

import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of} from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppStateService } from 'src/app/core/services/app-state/app-state.service';
import { FinancialSummary } from '../model/user';
import { IndividualService } from '../services/individual/individual.service';

@Injectable({ providedIn: 'root' })
export class FinancialSummaryResolver implements Resolve<Boolean> {

  constructor(
    private appState: AppStateService,
    private individualService: IndividualService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Boolean> {
    this.individualService.getEarnrFinancialSummary().pipe(
      // tap(data => this.appState.financialSummary = [])
      tap(data => this.appState.financialSummary = data)
    ).subscribe();
    return of(true);
  }
}
