<table cellspacing="0" class="table-view mat-elevation-z0" *ngIf="!isMobile">
  <tr *ngFor="let row of desktopData" (click)="row.rowClick?.action && triggerAction(row.id, row.rowClick.action)"
    [ngClass]="{ 'row-clickable': row.rowClick?.action }">
    <td *ngFor="let cell of row.cells" [width]="cell.width || ''"
      (click)="cell.onClick && triggerAction(row.id, cell.onClick, $event)">
      <!-- Content cell -->
      <div fxLayout="column" class="content" fxLayoutAlign="center start" *ngIf="cell.type === 'content'">
        <div class="label">{{ cell.label }}</div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <img [src]="cell.content.img" *ngIf="cell.content.img">
          <mat-icon *ngIf="cell.content.svgIcon" class="material-icons-outlined" [svgIcon]="cell.content.svgIcon">
          </mat-icon>
          <mat-icon *ngIf="cell.content.icon" class="material-icons-outlined">{{ cell.content.icon }}</mat-icon>
          <div class='value {{ cell.content.valueClass }}'>{{ cell.content.value }}</div>
          <ng-container *ngIf="cell.content.postIcon">
            <mat-icon *ngIf="cell.content.postIcon.svg"
              (click)="cell.content.postIcon.action && triggerAction(row.id, cell.content.postIcon.action, $event)"
              (mouseenter)="cell.content.postIcon.onHover && triggerAction(row.id, cell.content.postIcon.onHover, $event)"
              class="material-icons-outlined post-icon {{ cell.content.postIcon.class }}"
              [svgIcon]="cell.content.postIcon.svg"></mat-icon>
            <mat-icon *ngIf="cell.content.postIcon.icon"
              (click)="cell.content.postIcon.action && triggerAction(row.id, cell.content.postIcon.action, $event)"
              (mouseenter)="cell.content.postIcon.onHover && triggerAction(row.id, cell.content.postIcon.onHover, $event)"
              class="material-icons-outlined post-icon {{ cell.content.postIcon.class }}">{{ cell.content.postIcon.icon
              }}</mat-icon>
          </ng-container>
        </div>
      </div>

      <!-- Button cell -->
      <div fxLayout="column" class="button" fxLayoutAlign="center end" *ngIf="cell.type === 'button' && !cell.hide">
        <button mat-icon-button (click)="cell.action && triggerAction(row.id, cell.action)" class="row-button"
          [disableRipple]="true">
          <mat-icon *ngIf="cell.icon">{{ cell.icon }}</mat-icon>
          <span *ngIf="cell.text">{{ cell.text }}</span>
        </button>
      </div>

      <!-- Menu cell -->
      <div fxLayout="column" class="action" fxLayoutAlign="center center" *ngIf="cell.type === 'menu'">
        <mat-menu #menu="matMenu">
          <button mat-menu-item *ngFor="let item of cell.menuItems"
            (click)="triggerAction(row.id, item.action, $event)">
            <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
            <span>{{ item.label }}</span>
          </button>
        </mat-menu>
        <mat-icon [matMenuTriggerFor]="menu" #investmentsMenu="matMenuTrigger"
          (mouseenter)="cell.openOnHover && openInvestmentsMenu()">
          {{ cell.icon }}</mat-icon>
      </div>
    </td>
  </tr>
</table>

<ng-container *ngIf="isMobile">
  <div class="card-view" *ngFor="let mobileRow of mobileData" fxLayout="row" fxLayoutAlign="center start"
    (click)="mobileRow.button?.fullTap && mobileRow.button?.action && triggerAction(mobileRow.id, mobileRow.button.action)">
    <div fxLayout="row" fxFlex>
      <div class="prefix" fxLayout="column" fxLayoutAlign="start center">
        <img [src]="mobileRow.img" *ngIf="mobileRow.img">
        <mat-icon *ngIf="mobileRow.svgIcon" class="material-icons-outlined" [svgIcon]="mobileRow.svgIcon">{{
          mobileRow.icon }}</mat-icon>
        <mat-icon *ngIf="mobileRow.icon" class="material-icons-outlined">{{ mobileRow.icon }}</mat-icon>
      </div>
      <div fxFlex>
        <div *ngFor="let cardRow of mobileRow.cardLayout" class="row" fxLayout="row"
          fxLayoutAlign="space-between center">
          <div class="{{cardRow[0].class}}" fxFlex="100" fxLayout="row" fxLayoutAlign="start center">
            {{ cardRow[0].value }}
          </div>
          <div class="{{cardRow[1].class}}" fxLayout="row" fxLayoutAlign="end center"
            (click)="cardRow[1].action && triggerAction(mobileRow.id, cardRow[1].action, $event)" fxFlex="100">
            <mat-icon *ngIf="cardRow[1].svgIcon" [svgIcon]="cardRow[1].svgIcon"></mat-icon>
            <mat-icon *ngIf="cardRow[1].icon">{{ cardRow[1].icon }}</mat-icon>
            <div class="{{cardRow[1].class}}">{{ cardRow[1].value }}</div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="mobileRow.button && mobileRow.button.action && !mobileRow.button.hide">
      <div (click)="!mobileRow.button.fullTap && triggerAction(mobileRow.id, mobileRow.button.action)">
        <mat-icon *ngIf="mobileRow.button.svgIcon" [svgIcon]="mobileRow.button.svgIcon" class="postfix"></mat-icon>
        <mat-icon *ngIf="mobileRow.button.icon" class="postfix">{{ mobileRow.button.icon }}</mat-icon>
      </div>
    </ng-container>

    <div fxLayout="column" class="menu" fxLayoutAlign="center center" *ngIf="mobileRow.menu">
      <mat-menu #menu="matMenu">
        <button mat-menu-item *ngFor="let item of mobileRow.menu.menuItems"
          (click)="triggerAction(mobileRow.id, item.action, $event)" style="border-radius: 0px;">
          <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
          <span>{{ item.label }}</span>
        </button>
      </mat-menu>
      <mat-icon [matMenuTriggerFor]="menu" #investmentsMenu="matMenuTrigger" class="postfix">
        {{ mobileRow.menu.icon }}</mat-icon>
    </div>

  </div>
</ng-container>