import { Component, OnInit } from '@angular/core';
import { Currency } from '@earnr/earnr-shared/dist/models';
import { combineLatest, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppStateService } from 'src/app/core/services/app-state/app-state.service';

@Component({
  selector: 'app-currency-selector',
  templateUrl: './currency-selector.component.html',
  styleUrls: ['./currency-selector.component.scss']
})
export class CurrencySelectorComponent implements OnInit {

  currencies: Currency[];
  selectedCurrency!: Currency;

  constructor(private appState: AppStateService) {

    combineLatest([this.appState.selectedLegalAccount$, this.appState.selectedCurrency$])
      .pipe(
        switchMap(([activeAccount, activeCurrency]) => {
          this.currencies = this.appState.getSelectedLegalAccountCurrencies()

          console.log('Active legal account', activeAccount.displayName);
          console.log('Active currency', activeCurrency);

          this.selectedCurrency = activeCurrency

          return of(true)
        })
      ).subscribe()

  }

  ngOnInit(): void {
    this.selectedCurrency = this.appState.selectedCurrency;
  }

  selectCurrency(currency: Currency) {
    this.selectedCurrency = currency;
    this.appState.selectedCurrency = currency
  }

}
