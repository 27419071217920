 <div [formGroup]="form">

  <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="16px">
    
    <mat-form-field appearance="outline">
      <mat-label>{{ 'PROFILE.ADDRESS.FIELDS.COUNTRY' | translate }}</mat-label>
      <mat-select formControlName="country">
        <mat-option *ngFor="let country of preferredCountries" [value]="country.value">
          {{country.viewValue}}
        </mat-option>
        <mat-optgroup label="--------------">
        <mat-option *ngFor="let country of countries" [value]="country.value">
          {{country.viewValue}}
        </mat-option>
      </mat-optgroup>
      </mat-select>
      <mat-error *ngIf="form.get('country')!.invalid">
        {{ getErrorMessages('country') | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'PROFILE.ADDRESS.FIELDS.POSTCODE' | translate }}</mat-label>
      <input matInput type="text" formControlName="postcode">
      <mat-error *ngIf="form.get('postcode')!.invalid">{{ getErrorMessages('postcode') |
        translate }}
      </mat-error>
    </mat-form-field>

  </div>

  <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="16px">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'PROFILE.ADDRESS.FIELDS.UNIT_NUMBER' | translate }}</mat-label>
      <input matInput type="text" formControlName="unitNumber" minlength="1" maxlength="5">
      <mat-error *ngIf="form.get('unitNumber')!.invalid">{{ getErrorMessages('unitNumber') |
        translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'PROFILE.ADDRESS.FIELDS.STREET_NUMBER' | translate }}</mat-label>
      <input matInput type="text" formControlName="streetNumber" minlength="1" maxlength="5">
      <mat-error *ngIf="form.get('streetNumber')!.invalid">{{ getErrorMessages('streetNumber') |
        translate }}
      </mat-error>
    </mat-form-field>

  </div>


  <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="16px">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'PROFILE.ADDRESS.FIELDS.STREET_NAME' | translate }}</mat-label>
      <input matInput type="text" formControlName="streetName">
      <mat-error *ngIf="form.get('streetName')!.invalid">{{ getErrorMessages('streetName') |
        translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'PROFILE.ADDRESS.FIELDS.STREET_TYPE' | translate }}</mat-label>
      <input matInput type="text" formControlName="streetType">
      <mat-error *ngIf="form.get('streetType')!.invalid">{{ getErrorMessages('streetType') |
        translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="16px">

    <mat-form-field appearance="outline">
      <mat-label>{{ 'PROFILE.ADDRESS.FIELDS.SUBURB' | translate }}</mat-label>
      <input matInput type="text" formControlName="suburb">
      <mat-error *ngIf="form.get('suburb')!.invalid">{{ getErrorMessages('suburb') |
        translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="country.value === 'AU'">
      <mat-label>{{ 'PROFILE.ADDRESS.FIELDS.STATE' | translate }}</mat-label>
      <mat-select formControlName="state">
        <mat-option *ngFor="let state of states" [value]="state.value">
          {{state.viewValue}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.get('state')!.invalid">
        {{ getErrorMessages('state') | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline"  *ngIf="country.value !== 'AU'">
      <mat-label>{{ 'PROFILE.ADDRESS.FIELDS.STATE' | translate }}</mat-label>
      <input matInput type="text" formControlName="state">
      <mat-error *ngIf="form.get('state')!.invalid">{{ getErrorMessages('state') |
        translate }}
      </mat-error>
    </mat-form-field>

  </div>

</div>