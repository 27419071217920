/**
 * Fetching the investments ASYNC (not blocking). I make the resolver not wait but return a `true` right away.
 */

import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of} from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppStateService } from 'src/app/core/services/app-state/app-state.service';
import { NotificationService } from '../services/notification/notification.service';

@Injectable({ providedIn: 'root' })
export class NotificationResolver implements Resolve<Boolean> {

  constructor(
    private appState: AppStateService,
    private notificationService: NotificationService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Boolean> {
    
    this.notificationService.getNotifications()
    .pipe(
      tap(val => {
        this.appState.notifications = val;
      }),
    )
    .subscribe();
      return of(true);
  }
}
