import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppStateService } from '../../services/app-state/app-state.service';
import { iosSignUpUrl, mobileSignUpUrl, signUpUrl } from 'src/aws-exports';
import { LegalAccountService } from '../../services/legalAccount/legalAccount.service';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { Currency, LegalAccounts } from '@earnr/earnr-shared/dist/models';
import { IdTokenPayload } from '../../model/cognito';
import { Dictionary } from '../../model/global';


@Injectable({
  providedIn: 'root'
})
export class LegalAccountResolverGuard implements CanActivate {
  constructor(
    private appState: AppStateService,
    private legalaccountService: LegalAccountService,
    private authService: AuthService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.currentSession.pipe(
      switchMap(auth => {
        console.log('[LegalAccount Guard]', auth);
        if (!auth) {
          return of(false);
        }
        // try to pass in the cognito Id if we have it to prevent race condition where AUTH guard
        // hasn't set the individualId yet in the authstate which causes and error with fetching the legal accounts
        let cognitoUserId;
        if (typeof auth !== 'boolean') {
          const idTokenPayload = auth.getIdToken().payload as IdTokenPayload;
          cognitoUserId = idTokenPayload['cognito:username'];
        }
        return this.legalaccountService.getLegalAccounts(cognitoUserId)
          .pipe(
            map(legalAccounts => {
              console.log('[Fetch Legal accounts]', legalAccounts);
              this.appState.setLegalAccounts(legalAccounts);
              this.setCurrencies(legalAccounts);
              return legalAccounts;
            }),
            switchMap(legalAccounts => {
              /**
               * ===> Route check <===: If someone is still finalising the application redirect them back if they
               * try to login in to the dashboard.
               */
              return this.isUnfinishedApplication(legalAccounts);
            })
          )
      })
    )
  }

  private setCurrencies(legalAccounts: LegalAccounts[]) {

    let hasForeignCurrency = false;

    if (Array.isArray(legalAccounts) && legalAccounts.length > 0) {
      const currencyCollection = {} as Dictionary<Currency[]>;

      for (const legalAccount of legalAccounts) {
        const currencies = (legalAccount as any).currencies

        if (!hasForeignCurrency) {
          hasForeignCurrency = !!currencies.find((item: string) => item !== 'AUD');
        }

        currencyCollection[legalAccount.id] = currencies
      }

      this.appState.currencies = currencyCollection
      this.appState.showMultipleCurrencies = hasForeignCurrency;
    }

  }

  private isUnfinishedApplication(legalAccounts: LegalAccounts[]): Observable<boolean> {
    if (Array.isArray(legalAccounts) && legalAccounts.length === 0) {

      let continueSignupUrl = `${signUpUrl}/application/continue`;
      if (window.location.hostname.includes('ios.')) {
        continueSignupUrl = `${iosSignUpUrl}/application/continue`
      }
      else if (window.location.hostname.includes('mobile.')) {
        continueSignupUrl = `${mobileSignUpUrl}application/continue`
      }
      window.location.replace(continueSignupUrl);
      return of(false);
    }
    return of(true);
  }
}
