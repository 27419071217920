import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MaskService } from 'ngx-mask';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { validateAusMobile } from 'src/app/core/validators/phone.validator';
import { COUNTRY_PHONE_LIST } from 'src/assets/static/international-phone';

const AUSTRALIA_CODE = '+61';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
  providers: [MaskService],
})
export class PhoneComponent implements OnInit, OnDestroy {

  internationalNumbers = COUNTRY_PHONE_LIST.filter(item => item.code !== AUSTRALIA_CODE);
  preferredCountryCode = AUSTRALIA_CODE;
  codeControl = new UntypedFormControl(this.preferredCountryCode, [Validators.required]);
  phoneControl = new UntypedFormControl('');
  mobileMask: string;
  onDestroy = new Subject();

  get valid() {
    return this.codeControl.valid && this.phoneControl.valid;
  }

  @Input() tabindex: string;
  @Input() label: string;


  constructor(
  ) { }

  ngOnInit(): void {
    this.codeControl.valueChanges
      .pipe(
        takeUntil(this.onDestroy),
        startWith(AUSTRALIA_CODE)
      )
      .subscribe(val => {
        if (val === AUSTRALIA_CODE) {
          this.mobileMask = '0000 000 000';
          this.phoneControl.setValidators([Validators.required, validateAusMobile]);
        } else {
          this.mobileMask = '000000000000000';
          this.phoneControl.setValidators([Validators.required, Validators.maxLength(15)]);
        }
      });
    this.phoneControl.valueChanges
      .pipe(
        takeUntil(this.onDestroy)
      )
      .subscribe(val => {
        if (this.codeControl.value === AUSTRALIA_CODE && /^4\d*/.test(val)) {
          this.phoneControl.setValue(`0${val}`, {
            emitEvent: false,
          });
        }
      })
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  value() {
    return {
      internationalCode: this.codeControl.value,
      number: this.phoneControl.value,
    }
  }

  validate() {
    this.codeControl.markAllAsTouched();
    this.phoneControl.markAllAsTouched();
  }
}
