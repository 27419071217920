import { AfterViewInit, Component, EventEmitter, Inject, Input, OnDestroy, Output } from '@angular/core';
import { LinkedAccountPurpose } from '@earnr/earnr-shared/dist/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { YodleeLinkAccountResponse } from 'src/app/core/model/yodlee';
import { LegalAccountService } from 'src/app/core/services/legalAccount/legalAccount.service';
import { environment } from 'src/environments/environment';

declare global {
  interface Window {
    fastlink: {
      open(data: any, target: string): void
      close(): void
    };
  }
}

@Component({
  selector: 'app-link-bank',
  templateUrl: './link-bank.component.html',
  styleUrls: ['./link-bank.component.scss'],
  providers: [
    {
      provide: 'Window',
      useValue: window
    },
  ],
})
export class LinkBankComponent implements AfterViewInit, OnDestroy {

  @Output() action = new EventEmitter();
  @Output() account = new EventEmitter();
  @Output() loaded = new EventEmitter();
  @Input() flow: LinkedAccountPurpose;
  @Input() fsVersion: string;
  onDestroy = new Subject();


  linkAccount = true;
  loading = true;

  constructor(
    private legalAccountService: LegalAccountService,
    @Inject('Window') private window: Window,
  ) { }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    try {
      this.window.fastlink.close();
    } catch (error: any) {
      console.error('Error closing Fastlink', error.message);
    }
  }

  ngAfterViewInit(): void {
    this.legalAccountService.getYodleeToken()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(val => {
      const { yodlee, yodlee: { enabledVersion } }: any = environment;
      const yodleeVersion = this.fsVersion || enabledVersion;
      const yodleeEnv: { fastLinkURL: string, cdn: string } = yodlee[yodleeVersion];
      const configName = this.flow === LinkedAccountPurpose.AGGREGATION ?
        'Aggregation' : this.flow === LinkedAccountPurpose.VERIFICATION ?
          'Verification' : '';
      const userExperienceFlow = this.flow === LinkedAccountPurpose.AGGREGATION ?
        'Aggregation' : this.flow === LinkedAccountPurpose.VERIFICATION ?
          'Verification' : '';

      let selectedAccount: any;

      const fastLinkConfig = {
        params: {
          configName, // FS4
          userExperienceFlow, // FS3
        },
        fastLinkURL: yodleeEnv.fastLinkURL,
        accessToken: `Bearer ${val}`,
        forceIframe: true,
        iframeScrolling: 'auto',
        onLoad: (data: any) => {
          console.log("onLoad:");
          console.log(data);
        },
        // When running FS3, onSuccess is the only event being invoked passing back the Account information
        // When running FS4, onSuccess passes back account information, however, the onClose communicates the selected accounts.
        // Used for FS3 for Aggregation and for Verification
        onSuccess: (data: any) => {
          console.log("onSuccess:");
          console.log(data);
          if (data.status === 'SUCCESS') {
            // Fastlink4 uses the onClose hook to communicate the users selection
            if (yodleeVersion === 'v3') {
              selectedAccount = {
                additionalStatus: data.additionalStatus,
                providerAccountId: data.providerAccountId,
                providerId: data.providerId,
                providerName: data.bankName,
                requestId: data.requestId,
                status: data.status,
              };
              // this.linkAccount = false;
            }
          }
        },
        onError: (data: any) => {
          console.log("onError:");
          // data.code === 'N503'
          // data.title === 'Session Timeout'
          console.log(data);
          this.action.emit('ERROR');
        },

        // Used for FS3
        onExit: (data: any) => {
          console.log("onExit:");
          // data.code === 'N503'
          // data.title === 'Session Timeout'
          console.log(data);
          this.action.emit('CLOSE');
          // this.action.emit('ERROR');
        },
        // Used for FS4
        onClose: (data: YodleeLinkAccountResponse) => {
          console.log("onClose:");
          console.log(data);
          if (data.action === 'exit') {

            if (Array.isArray(data.sites) && data.sites.length === 1) {
              const account = data.sites[0];
              console.log(account);
              if (account.status === 'SUCCESS') {
                this.account.emit(account);
                this.linkAccount = false;
              } else if (account.status === 'FAILED') {
                this.action.emit('ERROR');
                this.linkAccount = false;
              } else if (account.status === 'ACTION_ABANDONED') {
                this.action.emit('CLOSE');  
                this.linkAccount = false;
              }
            } else if (data.status === 'USER_CLOSE_ACTION') {
              this.action.emit('CLOSE');
            }
          }
        },

        onEvent: (data: any) => {
          console.log("onEvent:");
          console.log(data);
          if (yodleeVersion === 'v3' && selectedAccount) {
            this.account.emit(selectedAccount);
          }
          
          if (data.fnToCall === 'renewClientSession') {
            this.loading = false;
            this.loaded.emit(true);
          }
        }
      };
      this.window.fastlink.open(fastLinkConfig, 'container-fastlink');
    });
  }
}
