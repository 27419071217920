<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px">
  <h4>Are you still with us?</h4>
  <p>For security reasons we will log you out in {{ counter$ | async }} seconds unless you close this dialog</p>
  <div >
    <button mat-flat-button color="primary" (click)="session('remainLoggedIn')">Keep me logged in</button>
  </div>
  <div>
    <button mat-button  (click)="session('logout')">Log me out</button>
  </div>
</div>
