import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import KeenSlider, { KeenSliderInstance } from 'keen-slider'

@Component({
  selector: 'column',
  templateUrl: './column.component.html',
  styleUrls: [
    './column.component.scss'
  ]
})
export class ColumnComponent {
  @Input() slides: any[] = []
  @Input() selectedIndex: number = 0

  @ViewChild('sliderRef') sliderRef: ElementRef<HTMLElement>

  slider: KeenSliderInstance | null = null

  @Output() selectedColumnIndex = new EventEmitter()

  ngAfterViewInit() {

    const mutationPlugin = (slider: KeenSliderInstance) => {
      const mutationObserver = new MutationObserver((mutations) => {
        mutations.forEach(mutation => {
          slider.update()
        })
      })

      const config = { childList: true }

      slider.on("created", () => {
        mutationObserver.observe(slider.container, config)
      })

      slider.on("destroyed", () => {
        mutationObserver.disconnect()
      })
    }

    this.createSlider(mutationPlugin)
  }

  createSlider(mutationPlugin: any) {
    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      mode: "snap",
      slides: {
        perView: 3,
        origin: 'center'
      },
      loop: true,
      vertical: true,
      initial: this.selectedIndex,
      created: ({ slides }) => {
        if (slides.length > 1) {
          const slide = slides[this.selectedIndex]
          slide.classList.add('selected')
        }
      },
      animationStarted: ({ slides }) => {
        const slide = slides[this.selectedIndex]
        slide.classList.remove('selected')
      },
      animationEnded: ({ track: { details: { rel } }, slides }) => {
        this.selectedIndex = rel
        const slide = slides[this.selectedIndex]
        slide.classList.add('selected')

        this.selectedColumnIndex.emit(this.selectedIndex)
      }
    }, [mutationPlugin])
  }

  title = 'column'
}