import { Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Auth } from 'aws-amplify';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { from, of, Subject } from 'rxjs';
import { catchError, map, takeUntil, timeout } from 'rxjs/operators';

import { Dictionary } from 'src/app/core/model/global';
import { AppStateService } from 'src/app/core/services/app-state/app-state.service';
import { LayoutService } from 'src/app/core/services/layout/layout.service';


interface MFARequest {
  error?: boolean;
  code?: string;
}
const initialMfaRequest = {
  error: false
};

@Component({
  selector: 'mfa-dialog',
  templateUrl: './mfa-dialog.component.html',
  styleUrls: ['./mfa-dialog.component.scss']
})
export class MFADialogComponent implements OnDestroy {

  fetchingCode = false;
  codeRequested = false;

  isMobile$ = this.layoutService.isMobile$
    .pipe(map(val => ({ mobile: !!val })));

  earnrCodeControl = new UntypedFormControl({ value: '', disabled: true }, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]);

  mfaRequestResult: MFARequest = { ...initialMfaRequest };

  labels = {
    requestCodeButton: _('PROFILE.MFA.REQUEST_CODE_BUTTON'),
    requestAnotherCodeButton: _('PROFILE.MFA.REQUEST_CODE_AGAIN_BUTTON'),
    CodeMismatchException: _('PROFILE.MFA.CODE_MISMATH_ERROR'),
    LimitExceededException: 'LimitExceededException',
    ExpiredCodeException: _('PROFILE.MFA.CODE_EXPIRED'),
  }
  onDestroy = new Subject();

  constructor(
    public dialogRef: MatDialogRef<MFADialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Dictionary<any>,
    private appState: AppStateService,
    private layoutService: LayoutService,
  ) {

  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  /**
   * Request a phone verification token
   */
  requestCode() {
    this.mfaRequestResult = { ...initialMfaRequest };
    this.fetchingCode = true;

    from(Auth.verifyCurrentUserAttribute('phone_number'))
      .pipe(
        takeUntil(this.onDestroy),
        timeout(500),
        catchError(error => {
          return of({
            error: true,
            code: error.code,
          });
        }),
      )
      .subscribe(result => {
        // some kind of rate limit error happened
        console.log('-> requet', result, typeof result !== 'string');
        // if (typeof result !== 'string') {

        // Undefined mean the call was
        if (result !== undefined) {
          this.mfaRequestResult = result as MFARequest;
        } else  {
          this.mfaRequestResult = {
            error: false,
            code: 'VerficationCodeSend',
          }
        }
        this.earnrCodeControl.enable();
        this.fetchingCode = false;
        this.codeRequested = true;
      });
  }

  submitToken() {
    if (!this.earnrCodeControl.valid) {
      return;
    }
    from(Auth.verifyCurrentUserAttributeSubmit('phone_number', `${this.earnrCodeControl.value}`))
      .pipe(
        takeUntil(this.onDestroy),
        catchError(error => {
          return of({
            error: true,
            code: error.code,
          });
        }),
        map(result => {
          return {
            error: typeof result === 'string' ? false : result.code,
            code: typeof result === 'string' ? result : result.code
          };
        }))
      .subscribe(result => {
        this.mfaRequestResult = result as MFARequest;
        if (this.mfaRequestResult.code === 'SUCCESS') {
          this.appState.mfaVerification = {
            verified: true
          };
          this.appState.mfaVerification2 = {
            [this.data.url]: {
              verified: true
            }
          };
          this.dialogRef.close(true);
        } else if (this.mfaRequestResult.error) {

        }
      });
  }


}
