<nav class="nav-bar" fxLayout="row" fxLayoutAlign="space-between center">

  <div fxLayout="row" fxLayoutGap="16">
    <!-- <app-bread-crumb fxFlex></app-bread-crumb> -->
    <h2 class="mat-h2 bottom-margin-0" fxFlex fxLayout="row" fxLayoutAlign="start center" fxFlex>
      <ng-container *ngIf="showNavBack">
        <mat-icon (click)="navBack()" class="navigate-back">arrow_back_ios</mat-icon>
      </ng-container>
      <span>{{ navBarTitle }}</span>

    </h2>
  </div>

  <div fxLayout="row" fxLayoutGap="16">

    <!-- Account Actions Menu -->
    <button mat-button [matMenuTriggerFor]="menu" [color]="accountBtnActive ? 'primary' : ''" disableRipple="true">
      <!-- <mat-icon class="material-icons-outlined">settings_outline</mat-icon> -->
      Account Actions
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="triggerAccountAction('account')"><span>{{ 'GENERAL.ROUTES.ACCOUNT' | translate
          }}</span></button>
      <button mat-menu-item (click)="triggerAccountAction('account/statements')"><span>{{ 'GENERAL.ROUTES.STATEMENTS' |
          translate
          }}</span></button>
      <button mat-menu-item (click)="triggerAccountAction('account/interest-summary')"><span>{{
          'GENERAL.ROUTES.INTEREST_SUMMARY' |
          translate
          }}</span></button>
      <button mat-menu-item (click)="triggerAccountAction('open-new-legal-account')"><span>{{
          'GENERAL.ROUTES.NEW_LEGAL_ACCOUNT' |
          translate
          }}</span></button>
    </mat-menu>

    <!-- Approvals button -->
    <button mat-button routerLink="earnr/approvals" [color]="approvalsBtnActive ? 'primary' : ''" disableRipple="true">
      <!-- <mat-icon class="material-icons-outlined">thumb_up_outline</mat-icon> -->
      Approvals
    </button>

    <!-- Notification button -->
    <button mat-button routerLink="notifications" class="notifications" disableRipple="true"
      [color]="inboxBtnActive ? 'primary' : ''" disableRipple="true">
      <mat-icon [matBadge]="notificationCount" matBadgeColor="accent">notifications_none</mat-icon>
      <span style="margin-left: 14px;">Inbox</span>
    </button>

    <!-- Profile button -->
    <button mat-button routerLink="profile" [color]="profileBtnActive ? 'primary' : ''" disableRipple="true">
      <mat-icon class="material-icons-outlined">person_outline</mat-icon>
      Profile
    </button>

    <!-- Logout button -->
    <button mat-flat-button class="logout-button" color="primary" routerLink="/auth/logout">
      <mat-icon>lock_outline</mat-icon>
      Log out
    </button>
  </div>

</nav>

<!-- Tabs are sitting outside of the section because we don't padding around the tabs container -->
<nav mat-tab-nav-bar *ngIf="showAccounts">
  <a mat-tab-link *ngFor="let account of accounts" (click)="selectAccount(account)"
    [active]="currentLegalAccount?.id == account.id">
    {{toTitleCase(account.displayName || account.name)}}
  </a>
</nav>

<!-- KLUDGE: A hack to show currencies in desktop only when accounts are not shown -->
<nav mat-tab-nav-bar *ngIf="!showAccounts && showCurrencies && showMultipleCurrenciesTab">
  <a mat-tab-link *ngFor="let currency of currencies" (click)="selectCurrency(currency)"
    [active]="currentCurrency === currency">
    {{ currency }}
  </a>
</nav>