import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { Currency, LegalAccount, LegalAccounts } from '@earnr/earnr-shared/dist/models';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Dictionary } from 'src/app/core/model/global';
import { AppStateService } from 'src/app/core/services/app-state/app-state.service';
import { IndividualService } from 'src/app/core/services/individual/individual.service';
import { LegalAccountService } from 'src/app/core/services/legalAccount/legalAccount.service';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { environment } from 'src/environments/environment';
import { iosSignUpUrl, mobileSignUpUrl, signUpUrl } from 'src/aws-exports';


// const ENABLE_ACCOUNT_SELECTOR = [
//   '/account',
//   '/dashboard/welcome',
//   '/earnr',
// ];

export interface Layout {
  openSideNav: boolean;
  opened: boolean;
  mode: MatDrawerMode;
  fixedViewPort: boolean;
  fixedTopGap: number;
}

const defaultLayout: Layout = {
  openSideNav: true,
  opened: true,
  mode: 'side',
  fixedViewPort: true,
  fixedTopGap: 64,
};

@Component({
  selector: 'app-authenticated-layout',
  templateUrl: './authenticated-layout.component.html',
  styleUrls: ['./authenticated-layout.component.scss'],
})
export class AuthenticatedLayoutComponent implements OnInit {
  layout: Layout = defaultLayout;
  currentDevice: Dictionary<boolean> = {};
  isMobile = false;
  applicationUrl$: Observable<string>;
  showMultipleCurrenciesTab: Boolean;

  events: string[] = [];
  // deviceMap$: Observable<Dictionary<boolean>>;
  currentUser$ = this.appState.currentUser$;
  accounts$ = this.appState.legalAccounts$;

  toolbarConfig$: Observable<any>;
  selectedAccount!: LegalAccount;
  selectedCurrency!: Currency;
  currencies: Currency[];

  notifications$ = this.appState.notifications$
    .pipe(
      map(notifications => notifications.length)
    );
  mobileNavBarTitle$: Observable<string>;
  navBarTitle$: Observable<string>;
  mobileNavBarTitle: string;
  navBarTitle: string;
  hideMobileBottomBar = false;
  onDestroy = new Subject();


  constructor(
    private layoutService: LayoutService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private translateService: TranslateService,
    private active: ActivatedRoute,
    private appState: AppStateService,
    private individualService: IndividualService,
    private legalAccountService: LegalAccountService

  ) {
    this.layoutService.breakpoint$
      .pipe(
        takeUntil(this.onDestroy),
        tap(deviceMap => {
          if (deviceMap['is-mobile'] || deviceMap['is-tablet-portrait']) {
            this.isMobile = true;
            this.layout = {
              ...this.layout,
              mode: 'over',
              opened: false
            };
          } else {
            this.isMobile = false;
            this.layout = {
              ...this.layout,
              mode: 'side',
              opened: true
            }
          }
        })
      ).subscribe();


    // Show Welcome dialog after first signup, OR when there are notifications
    combineLatest([this.appState.notifications$, this.appState.currentUser$, this.appState.currencies$, this.appState.showMultipleCurrencies$])
      .pipe(
        filter(data => !!Object.keys(data[1]).length),
        switchMap(data => {
          const [notifications, currentUser, currencies, showMultipleCurrencies] = data;

          this.showMultipleCurrenciesTab = showMultipleCurrencies;

          if (this.router.url === '/' || this.router.url === '/dashboard') {
            const currenciesSet = new Set<Currency>();

            Object.values(currencies).forEach((currency: Currency[]) => {
              currency.forEach(item => currenciesSet.add(item))
            })

            this.currencies = Array.from(currenciesSet)
          }
          else {
            this.currencies = this.appState.getSelectedLegalAccountCurrencies()
          }

          // if ((currentUser.showWelcome ||
          //   (notifications.length > 0 && environment.showNotificationDialog)) &&
          //   !this.appState.notificationDialogShowed) {
          //   this.appState.showWelcome = true;
          //   this.appState.notificationDialogShowed = true;
          //   if (currentUser.showWelcome) {
          //     return this.individualService.updateIndividual({ showWelcome: false })
          //   }
          // }
          return of(true);
        })
      )
      .subscribe();

    this.applicationUrl$ = this.legalAccountService.getUnfinishedApplications().pipe(
      map(applications => {

        let url = `${signUpUrl}/application`;
        if (window.location.hostname.includes('ios.')) {
          url = `${iosSignUpUrl}/application`
        }
        else if (window.location.hostname.includes('mobile.')) {
          url = `${mobileSignUpUrl}application`
        }

        if (Array.isArray(applications) && applications.length) {
          return `${url}/continue`
        }
        return url
      })
    )

    this.toolbarConfig$ = this.router.events
      .pipe(
        // we only want one NavigationEnd  event to filter through
        filter((event: Event) => {
          if (event instanceof NavigationEnd) {
            if (this.router.url === '/' || this.router.url === '/dashboard') {
              const currenciesSet = new Set<Currency>();

              Object.values(this.appState.currencies).forEach((currency: Currency[]) => {
                currency.forEach(item => currenciesSet.add(item))
              })
              this.currencies = Array.from(currenciesSet);
              this.selectedCurrency = this.appState.selectedCurrency;
            }
            else {
              this.currencies = this.appState.getSelectedLegalAccountCurrencies()
            }

            return true
          }
          return false
        }),
        // on first page load the ActivationStart is not triggered, so we start with the correct snapshot
        startWith(this.active.children[0].children[0]),
        // the toolbar info is only consistently available in the childrens children of the active snapshot
        // For some reason we have to go as deep as possible into the route tree and to find data.
        // If the children on a deepler level do not exist it seems to pick up the data from the higher level.
        // Try/catch to prevent the subscription from breaking. Work in progress for now.
        switchMap(data => {
          try {
            // this is not ideal :(
            return this.active.children[0].children[0].children[0].data;
          } catch (error) {
            // return a default config
            return of({ toolbarConfig: { mobile: ['navBack', 'smallLogo'] } })
          }
        }),
        // temp fix to display title for mobile view coming from router config
        tap(data => {
          // @ts-ignore
          this.hideMobileBottomBar = data.hideMobileBottomBar ? true : false;
          const { toolbarConfig: { mobile } } = data;
          if (mobile && mobile.filter((val: string) => val === 'mobileNavBarTitle').length === 1) {
            // @ts-ignore
            this.mobileNavBarTitle = this.translateService.instant(data.title);
          }
          // @ts-ignore
          this.navBarTitle = this.translateService.instant(data.title);
        }),
        map(data => data.toolbarConfig || {}),
      );
    // this.accounts$ = this.router.events
    //   .pipe(
    //     filter((event: Event) => event instanceof NavigationEnd),
    //     startWith(this.router),
    //     switchMap(e => {
    //       const event = e as NavigationEnd;
    //       const url = event.url.replace(/\?.*/, '');
    //       if (ENABLE_ACCOUNT_SELECTOR.includes(url)) {
    //         return this.appState.legalAccounts$;
    //       } else {
    //         return of([]);
    //       }
    //     })
    //   );
  }

  ngOnInit(): void {
    this.selectedAccount = this.appState.selectedLegalAccount;
    this.selectedCurrency = this.appState.selectedCurrency;
    this.mobileNavBarTitle$ = this.layoutService.mobileNavBarTitle$;
    this.navBarTitle$ = this.layoutService.navBarTitle$;

    this.layoutService.mobileNavBarTitle$.subscribe(val => {
      this.mobileNavBarTitle = val;
      this.ref.detectChanges();
    });

    this.layoutService.navBarTitle$.subscribe(val => {
      this.navBarTitle = val;
      this.ref.detectChanges();
    });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  selectAccount(account: LegalAccounts) {
    this.appState.selectedLegalAccount = account;
    this.selectedAccount = account;
    this.currencies = this.appState.getSelectedLegalAccountCurrencies()

    if (!this.currencies.includes(this.appState.selectedCurrency)) {
      this.selectCurrency(this.currencies[0]);
    }

  }
  selectCurrency(currency: Currency) {
    this.selectedCurrency = currency;
    this.appState.selectedCurrency = currency

    if (!this.appState.getSelectedLegalAccountCurrencies().includes(currency)) {
      console.log('selected legal account ', this.appState.selectedLegalAccount.displayName, 'does not include currency', currency);
      for (const account of this.appState.legalAccounts) {
        const currenciesOfLegalAccount = this.appState.currencies[account.id];
        if (currenciesOfLegalAccount.includes(currency)) {

          console.log('found legal account', account.displayName, 'with currency', currency)

          this.appState.selectedLegalAccount = account;
          this.selectedAccount = account;
          this.selectedCurrency = currency;
          this.appState.selectedCurrency = currency;
          break;
        }
      }
    }

  }
}
