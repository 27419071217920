import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of} from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppStateService } from 'src/app/core/services/app-state/app-state.service';
import { User } from '../model/user';
import { IndividualService } from '../services/individual/individual.service';

@Injectable({ providedIn: 'root' })
export class CurrentUserResolver implements Resolve<User | Boolean> {

  constructor(
    private appState: AppStateService,
    private individualService: IndividualService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<User | Boolean> {
    this.individualService.getCurrentUser()
      .pipe(
        tap(user => {
          this.appState.currentUser = {
            ...user,
          };
        })
      ).subscribe();
    return of(true);
  }
}
