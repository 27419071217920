
/*
******* NOTICE **********
This is an auto generated file. Please DO NOT modify !!
if you want to add any new config, please have a look at aws-init.ts
*************************
*/

export interface AwsConfig {
  aws_project_region: string;
  aws_appsync_graphqlEndpoint: string;
  aws_appsync_region: string;
  aws_appsync_authenticationType: string;
  aws_cognito_identity_pool_id: string;
  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  aws_user_pools_mobile_client_id: string;
  oauth: {};
  aws_user_files_s3_bucket: string;
  aws_user_files_s3_bucket_region: string;
  acm_arn: string;
  addressFinderApiKey: string;
  mobile_app_distribution_domain: string;
  mobile_app_distribution_id: string;
  featureToggles: {
    disableYodlee: boolean;
    disableAuthRep: boolean;
  }
};

 export const awsConfig: AwsConfig = {
  aws_project_region: 'ap-southeast-2',
  aws_appsync_graphqlEndpoint: 'https://xzzi3c5rivbixhqzzdmaz2nwrm.appsync-api.ap-southeast-2.amazonaws.com/graphql',
  aws_appsync_region: 'ap-southeast-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cognito_identity_pool_id: 'ap-southeast-2:a2adb081-207e-4ec9-a1e1-3ecad29ffa90',
  aws_cognito_region: 'ap-southeast-2',
  aws_user_pools_id: 'ap-southeast-2_HA0eJGffZ',
  aws_user_pools_web_client_id: '5l3srj2gnsvdtkfpc1mnss447',
  aws_user_pools_mobile_client_id: '64abhld6mrldghn1mq4nulsf54',
  oauth: {},
  aws_user_files_s3_bucket: 'earnr-development-app-user-files',
  aws_user_files_s3_bucket_region: 'ap-southeast-2',
  acm_arn: 'arn:aws:acm:us-east-1:994402318879:certificate/475ddc4f-4a08-46a0-bf64-1f29afd3b6f7',
  addressFinderApiKey: 'UBLJW3V4MADFQXHPYRGN',
  mobile_app_distribution_domain: 'd2s38pgl12riyc.cloudfront.net',
  mobile_app_distribution_id: 'E19D0SU5ZK4D4V',
  featureToggles: { disableYodlee: false, disableAuthRep: false }
};

 export const signUpUrl = 'https://signup.dev.earnr.com.au';

 export const marketingUrl = 'https://dev.earnr.com.au';

 export const iosSignUpUrl = 'https://ios.signup.dev.earnr.com.au';

 export const mobileSignUpUrl = 'https://mobile.dev.earnr.com.au/signup/';
