import { Injectable } from '@angular/core';
// import { graphqlOperation, GraphQLSubscription } from '@aws-amplify/api';
// import API, { graphqlOperation, GraphQLSubscription } from 'aws-amplify';
import { API } from 'aws-amplify';
// import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
// import Auth from '@aws-amplify/auth';
// import gql from 'graphql-tag';

import { AWSAppSyncRealTimeProvider } from '@aws-amplify/pubsub';
import ZenObservable from 'zen-observable-ts';
import { from, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

export interface GraphQLResponse {
  data?: any;
}

export interface GraphQlOperationParams {
  [key: string]: any;
}

export interface SubscriptionResponse {
  provider: AWSAppSyncRealTimeProvider,
  value: GraphQLResult<any>;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor() {
  }

  subscription<V>(statement: string, type: string) {
    /*
      Amplify uses Zen Observable and we use RxJS. So have to convert to be able to 
      use RxJS operators
     */
    const zenToRx = <T>(zenObservable: ZenObservable<T>): Observable<T> =>
      new Observable(
        observer => zenObservable.subscribe(observer)
      );

    const subscription = zenToRx<SubscriptionResponse>(API.graphql({
      query: statement,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    }) as any);
    return subscription.pipe(
      map(response => { 
        let data = JSON.parse(response.value.data[type].data);
        return {
          ...response.value.data[type],
          data: JSON.parse(response.value.data[type].data),
        };
      }) 
    ) as Observable<V>

  }

  graphql<V>(statement: string, params: GraphQlOperationParams, type: string, iam: boolean = false) {
    const payload: any = {
      query: statement,
      authMode: iam ? GRAPHQL_AUTH_MODE.AWS_IAM : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    }
    if (params) {
      payload.variables = params;
    }
    const response = API.graphql(payload) as Promise<GraphQLResult<object>>
    return from(response)
      .pipe(
        map((res: GraphQLResponse) => res.data[type]),
      ) as Observable<V>;
  }
}
