import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './modules/material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import * as fromComponents from './components';
import { HttpClientModule } from '@angular/common/http';
import { InterpolatePipe } from './pipes/interpolate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { NgXScrollSelectorModule } from './modules/ngx-scroll-selector/scroll-selector.module';
import { AuthoriseTransferDialogComponent } from './components/authorise-transfer-dialog/authorise-transfer-dialog.component';

@NgModule({
  declarations: [
    ...fromComponents.components,
    NotificationListComponent,
    AuthoriseTransferDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    FlexLayoutModule.withConfig({
      useColumnBasisZero: false, // fix for Safari when applying fxFlex
    }),
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    NgXScrollSelectorModule,
    NgxMaskModule.forChild(),
    ClipboardModule,
  ],
  exports: [
    MaterialModule,
    FlexLayoutModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    NgxMaskModule,
    NgXScrollSelectorModule,
    ...fromComponents.components,
  ],
  providers: [
    InterpolatePipe,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { hideRequiredMarker: true } }
  ]
})
export class SharedModule { }
