import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LOCALE_ID } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import svgIcons from '../assets/images/icons/svg/manifest.json';

export function createTranslateLoader(http: HttpClient) {
  let version = Date.now();
  if ((window as any).earnr?.hash) {
    version = (window as any).earnr?.hash
  }
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + version);
}
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    // ngx-translate and the loader module
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        // useFactory: HttpLoaderFactory,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    SharedModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-au' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    // register custom SVG icons
    svgIcons.forEach(icon => {
      this.matIconRegistry.addSvgIcon(icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`./assets/images/icons/svg/${icon.value}`)
      );
    })
  }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
