import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {  Dictionary, Notification } from '@earnr/earnr-shared/dist/models';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { NotificationService } from 'src/app/core/services/notification/notification.service';

interface NotificationViewItem {
  id: string;
  type: string;
  title: string;
  message?: string;
  dismissable?: boolean;
  icon?: { svgIcon?: string, matIcon?: string },
  link?: { slug: string, queryParams: Dictionary<string | number> };
}


@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnDestroy {

  @Output() action = new EventEmitter();
  @Input() isMobile: boolean;
  @Input()
  set notifications(notifications: Notification[]) {
    this.notificationCount = notifications.length === 0 ? '' : `${notifications.length}`;
    this._notifications = this.transformNotifications(notifications);
  }
  _notifications: NotificationViewItem[] = [];
  notificationCount: string;
  isMobile$ = this.layoutService.isMobile$
    .pipe(map(val => ({ mobile: !!val })));
  onDestroy = new Subject();

  constructor(
    private layoutService: LayoutService,
    private notificationService: NotificationService,
  ) { }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
  private transformNotifications(notifications: Notification[]): NotificationViewItem[] {
    const items: NotificationViewItem[] = [];
    console.log(notifications)
    for (const notification of notifications) {
      const metaData = JSON.parse(notification.metaData);
      if (!metaData) {
        items.push({
          id: notification.id,
          title: notification.title,
          message: notification.message,
          type: '',
        });
      } else if (metaData.type === 'TRANSFER_FUNDS') {
        items.push({
          id: notification.id,
          title: notification.title,
          message: notification.message,
          icon: { svgIcon: 'earnr_transfer_funds' },
          type: 'TRANSFER_FUNDS',
          link: { slug: `/earnr/transfer-instructions`, queryParams: { id: metaData.investmentId } }
        });
      } else if (metaData.type === 'AUTHORISE_TRANSFER') {
        items.push({
          id: notification.id,
          title: notification.title,
          message: notification.message,
          type: 'AUTHORISE_TRANSFER',
          link: { slug: `/earnr/approvals`, queryParams: {} }
        });
      }
      else if (metaData.type === 'INVESTMENT_STARTED') {
        items.push({
          id: notification.id,
          title: notification.title,
          message: notification.message,
          type: 'INVESTMENT_STARTED',
          dismissable: true,
        });
      }

      // if (!legalAccount.bank.number) {
      //   this.addNotification({
      //     id: '1',
      //     type: 'ACTION',
      //     title: 'Add bank account for monthly interest payments',
      //     icon: { matIcon: 'playlist_add ' },
      //     link: { slug: `/connect`, }
      //   });
      // }
      // if (legalAccount.status === LegalAccountStatus.UNCOMFIRMED) {
      //   notifications.push({
      //     id: '1',
      //     type: 'ACTION',
      //     title: 'Account not verified',
      //     icon: { matIcon: 'info_outline ' },
      //     link: { slug: `/connect`, }
      //   });
    }
    return items;
  }

  doAction(notification: any) {
    this.action.emit(notification);
  }

  delete(id: string) {
    this.notificationService.deleteNotification(id)
      .pipe(takeUntil(this.onDestroy))
      .subscribe();
  }
}
