import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-yodlee-loader',
  template: ``,
})
export class YodleeLoaderComponent implements OnInit, OnDestroy {

  @Input() fsVersion: string;
  private scriptTag!: HTMLScriptElement;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private render: Renderer2,
  ) {

  }

  ngOnInit() {
    this.createScriptTag();
  }

  ngOnDestroy() {
    this.render.removeChild(this._document, this.scriptTag);
  }

  private createScriptTag() {
    const { yodlee, yodlee: { enabledVersion } }: any = environment;
    const yodleeVersion = this.fsVersion || enabledVersion;
    const config: { fastLinkURL: string, cdn: string } = yodlee[yodleeVersion];

    const firstTag = this._document.getElementsByTagName('SCRIPT')[0];
    if (firstTag.id !== 'yodlee') {
      this.scriptTag = this.render.createElement('SCRIPT') as HTMLScriptElement;
      this.scriptTag.async = true;
      this.scriptTag.id = 'yodlee';
      this.scriptTag.src = config.cdn;

      this.render.insertBefore(firstTag.parentNode, this.scriptTag, firstTag);
    }
  }
}
