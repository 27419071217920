<mat-form-field [appearance]="appearance" floatLabel="always">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input matInput type="text" [formControl]="_addressCtrl" #addressInput [placeholder]="placeholder" name="finder" autocomplete="chrome-off" >
  <mat-error *ngIf="_addressCtrl.touched && _addressCtrl.invalid">{{ getErrorMessages(_addressCtrl) | translate }}
  </mat-error>
</mat-form-field>
<div class="no-results-found" *ngIf="noAddressFound">
  <span> {{ 'ADDRESS_FINDER.NO_RESULT_MESSAGE' | translate }}</span>
  <a (click)="showManualEntry(type)">{{ 'ADDRESS_FINDER.ENTER_MANUALLY' | translate }}</a>
 </div>
<div #addressContainer class="autocomplete-container"></div>