import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { AppStateService } from 'src/app/core/services/app-state/app-state.service';
import { IndividualService } from 'src/app/core/services/individual/individual.service';
import { WelcomeComponent } from '../welcome/welcome.component';

@Component({
  selector: 'app-show-welcome-dialog',
  template: ``,
})
export class ShowWelcomeDialogComponent implements OnInit, OnDestroy {

  onDestroy = new Subject();
  welcomeDialogRef!: MatDialogRef<WelcomeComponent>;

  constructor(
    private appState: AppStateService,
    private individualService: IndividualService,
    public dialog: MatDialog,
  ) { }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }


  ngOnInit(): void {
    this.appState.showWelcome$.pipe(
      takeUntil(this.onDestroy),
      // update individual so disable the showWelcome flag
      filter(val => val === true),
      // switchMap(val => {
      //   return this.individualService.updateIndividual({ showWelcome: false })
      //   .pipe(
      //     map(data => val)
      //   )
      // })
    ).subscribe(val => {
      if (val) {
        this.appState.showWelcome = false;
        window.setTimeout( () => {
          this.openWelcomeDialog();
        }, 1000);
      }
    })
  }

  openWelcomeDialog(): void {
    this.welcomeDialogRef = this.dialog.open(WelcomeComponent, {
      panelClass: 'welcome-dialog',
      data: {
        ref: this.welcomeDialogRef
      }
    });
    this.welcomeDialogRef.componentInstance.modal_principal_parent.subscribe(val => {
      if (val === 'CLOSE_PARENT_MODAL') {
        this.welcomeDialogRef.close();
      }
    });

    this.welcomeDialogRef.afterClosed().subscribe(result => {


    });
  }

}
