<nav fxLayout="row" fxLayoutAlign="center center" [ngClass]="{'mobile-app': isMobileApp, 'is-ios-app': isIOSApp }">
  <div
    *ngFor="let navItem of navItems"
    routerLink="{{navItem.routerLink}}"
    routerLinkActive="active-link"
    [routerLinkActiveOptions]="{exact: true}"
    fxLayout="column" fxLayoutAlign="center center"
    fxFlex>
    <mat-icon [class]="navItem.class" *ngIf="navItem.svgIcon" [svgIcon]="navItem.svgIcon"></mat-icon>
    <mat-icon [class]="navItem.class" *ngIf="navItem.icon">{{ navItem.icon }}</mat-icon>
    <label>{{navItem.label! | translate}}</label>
  </div>
</nav>