import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AppStateService } from 'src/app/core/services/app-state/app-state.service';
import { User } from 'src/app/core/model/user';
import { LayoutService } from 'src/app/core/services/layout/layout.service';
import { InvestmentProduct, Notification } from '@earnr/earnr-shared/dist/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';


@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  @Output() modal_principal_parent = new EventEmitter();
  notifications$: Observable<Notification[]> = this.appState.notifications$;
  
  isMobile$ = this.layoutService.isMobile$
    .pipe(map(val => ({ mobile: !!val })));

  currentUser!: User;
  get firstName() {
    return this.currentUser && this.currentUser.firstName ? this.currentUser.firstName : '';
  }

  get product() {
    const productName = this.appState.investments[this.appState.selectedLegalAccount.id][0].productName;
    return InvestmentProduct.getProductName(productName);
  }

  get hasInvestment() {
    return !!this.appState.investments[this.appState.selectedLegalAccount.id].length;
  }

  get investmentId() {
    const investment = this.appState.investments[this.appState.selectedLegalAccount.id][0];
    return investment.id;
  }

  showWelcome = false;

  constructor(
    private layoutService: LayoutService,
    private appState: AppStateService,
    private router: Router,
  ) { 
    
  }

  ngOnInit(): void {
    this.currentUser = this.appState.currentUser;
    this.showWelcome = this.appState.currentUser.showWelcome;
  }

  doAction(notification: any) {
    if (notification.type === 'TRANSFER_FUNDS') {
      if (notification.link) {
        this.router.navigate([notification.link.slug], { queryParams: notification.link.queryParams });
      }
    }

    if (notification.type === 'AUTHORISE_TRANSFER') {
      if (notification.link) {
        this.router.navigate([notification.link.slug]);
      }
    }

    this.modal_principal_parent.emit('CLOSE_PARENT_MODAL');
  }

}
