import { Injectable } from '@angular/core';
import { Address, Dictionary, LoginHistory } from '@earnr/earnr-shared/dist/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FinancialSummary, User } from '../../model/user';
import { ApiService } from './../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class IndividualService {

  constructor(
    private apiService: ApiService,
  ) { }

  /**
   * Fetch the Last logged in time for this user
   */
   getLastLoggedIn(): Observable<User> {
    const statement = `query getIndividual {
        getIndividual {
          id
          createdAt
          loginHistory {
            lastLoggedIn
            userAgent
            platform
          }
        }
      }`;
    return this.apiService.graphql<User>(statement, {}, 'getIndividual').pipe(
      map(individual => {
        return {
          ...individual,
        }
      })
    )
  }

  /**
   * Fetch the curent logged in Individual
   */
  getCurrentUser(): Observable<User> {
    const statement = `query getIndividual {
        getIndividual {
          contactDetails {
            email
            phone
          }
          firstName
          lastName
          address {
            country
            fullAddress
            postcode
            state
            streetName
            streetNumber
            streetType
            suburb
            unitNumber
            manual
          }
          residentialAddress {
            country
            fullAddress
            postcode
            state
            streetName
            streetNumber
            streetType
            suburb
            unitNumber
            manual
          }
          postalAddress {
            country
            fullAddress
            postcode
            state
            streetName
            streetNumber
            streetType
            suburb
            unitNumber
            manual
          }
          loginHistory {
            lastLoggedIn
            userAgent
            platform
          }
          showWelcome
          linkedAccounts {
            providerId
            accountId
            providerAccountId
            providerName
            accountName
            container
            type
            purpose
            interestRate
            lazyMoney
            currentBalance
            accountHolderName
          }
        }
      }`;
    return this.apiService.graphql<User>(statement, {}, 'getIndividual').pipe(
      map(individual => {
        return {
          ...individual,
        }
      })
    )
  }

  /**
   * Update the Invidivual data.
   */
   updateIndividual(data: UpdateIndividualInput): Observable<boolean> {
    const input: Dictionary<any> = {
      ...data
    };
    const statement = `mutation updateIndividual($input: UpdateIndividualInput) {
      updateIndividual(input: $input)
    }`;
    return this.apiService.graphql<boolean>(statement, { input }, 'updateIndividual');
  }

  /**
   * 
   * Fetches the financial summary which is to be displayed on the dashboard overview
   * 
   */
  getEarnrFinancialSummary(): Observable<FinancialSummary[]> {
    const statement = `query getEarnrFinancialSummary {
      getEarnrFinancialSummary {
        type
        averageReturn
        balance
      }
    }`;

    return this.apiService.graphql<FinancialSummary[]>(statement, {}, 'getEarnrFinancialSummary');
  } 

}

interface UpdateIndividualInput {
  id?: string;
	tfn?: string;
	residential?: Address;
	postal?: Address;
	email?: string;
	mobile?: string;
  loginHistory?: LoginHistory[];
  showWelcome?: boolean;
}