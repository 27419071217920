export const getDevice = () => {
  const isLocalHost = window.location.hostname === 'localhost';
  const isMobileApp = window.location.hostname.includes('mobile.');
  const isAndroidApp = (window as any).isAndroidApp === 'true'; // injected by webview
  // const isAndroidApp = true;
  const isIOSApp = (window as any).isIOSApp === 'true'; // injected by webview
  const isNativeApp = (window as any).isNativeApp === 'true' || isAndroidApp || isIOSApp; // injected by webview
  // const isNativeApp = true;

  return {
    isNativeApp,
    isIOSApp,
    isAndroidApp,
    isMobileApp,
    isLocalHost
  }
}

export const hideMobileAppLoader = () => {
  const { isNativeApp } = getDevice();

  if (isNativeApp) {
    try {
      if ((window as any).Android) {
        (window as any).Android.setLoading('false');
      }
      if ((window as any).webkit) {
        (window as any).webkit.messageHandlers.dashboardLoaded.postMessage('loaded');
      }
    } catch (err) {
      console.log('Can not reach native code');
    }
  }
}