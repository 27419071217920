<div class="wrapper" [ngClass]="{'is-mobile': isMobile }" fxFlex>
  <div class="list" fxLayout="column" fxFlex.gt-lg="70" fxFlex="100" [ngClass]="{'is-mobile': isMobile }">
    <!-- Header -->
    <div fxLayout="row" class="row " fxLayoutAlign="space-between center">
      <div [matBadge]="notificationCount" matBadgeColor="accent" matBadgeOverlap="false" class="header">{{
        'NOTIFICATIONS.OVERVIEW.LIST.HEADING' | translate }}</div>
    </div>

    <!-- Iterate over the messages -->
    <div fxLayout="row" class="row" fxLayoutAlign="space-between center" *ngFor="let notification of _notifications"
      (click)="doAction(notification)">

      <mat-icon *ngIf="notification.icon && notification.icon.matIcon">{{ notification.icon.matIcon }}</mat-icon>
      <mat-icon *ngIf="notification.icon && notification.icon.svgIcon" [svgIcon]="notification.icon.svgIcon"></mat-icon>
      <div fxFlex>{{ notification.title }}</div>
      <mat-icon *ngIf="notification.dismissable" (click)="delete(notification.id)"
        class="delete-button">delete_outline</mat-icon>

      <div class="icon-button" *ngIf="notification.type === 'TRANSFER_FUNDS'">
        <mat-icon *ngIf="!isMobile.mobile">arrow_forward_ios</mat-icon>
        <mat-icon *ngIf="isMobile.mobile" svgIcon="earnr_chevron_right_small"></mat-icon>
      </div>

      <div class="icon-button" *ngIf="notification.type === 'AUTHORISE_TRANSFER'">
        <mat-icon *ngIf="!isMobile.mobile">arrow_forward_ios</mat-icon>
        <mat-icon *ngIf="isMobile.mobile" svgIcon="earnr_chevron_right_small"></mat-icon>
      </div>

    </div>

    <!-- No notifications -->

    <div fxLayout="row" class="row" fxLayoutAlign="space-between center" *ngIf="!_notifications.length">
      <div fxLayout="column" fxFlex="100" fxFlex.gt-lg="70">
        <div>{{ 'NOTIFICATIONS.OVERVIEW.LIST.NO_RECORDS' | translate }}</div>
      </div>
    </div>
  </div>
</div>