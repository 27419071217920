import { Injectable } from '@angular/core';
import { Investment, LegalAccount, LegalAccounts, Rate, Notification, StatementReport, Currency } from '@earnr/earnr-shared/dist/models';

import { BehaviorSubject, Subscription } from 'rxjs';

import { Application } from '../../model/application';
import { MfaVerification, MfaVerification2 } from '../../model/cognito';
import { Dictionary } from '../../model/global';
import { FinancialSummary, User } from '../../model/user';
import { LegalAccountLiquidity } from '../legalAccount/legalAccount.service';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  _showWelcome = new BehaviorSubject<Boolean>(false);
  showWelcome$ = this._showWelcome.asObservable();

  _showMultipleCurrencies = new BehaviorSubject<Boolean>(false);
  showMultipleCurrencies$ = this._showMultipleCurrencies.asObservable();

  set showMultipleCurrencies(val: Boolean) {
    this._showMultipleCurrencies.next(val);
  }

  get showMultipleCurrencies() {
    return this._showMultipleCurrencies.value;
  }

  notificationDialogShowed = false;

  set showWelcome(val: Boolean) {
    this._showWelcome.next(val);
  }

  _graphqlSubscriptions: Subscription[] = [];
  set graphqlSubscriptions(subscription: Subscription[]) {
    this._graphqlSubscriptions.push(...subscription);
  }
  get graphqlSubscriptions() {
    return this._graphqlSubscriptions;
  }

  /**
   * Legal Account State management
   */
  private _legalAccounts = new BehaviorSubject<LegalAccounts[]>([]);
  legalAccounts$ = this._legalAccounts.asObservable();

  set legalAccounts(legalAccounts: LegalAccounts[]) {
    this._legalAccounts.next(legalAccounts);
    // force a refresh of the select legal account to all subscribers
    if (legalAccounts.length) {
      this.selectedLegalAccount = legalAccounts
        .filter(account => account.id === this._selectedLegalAccount.value.id)[0];
    }
  }
  get legalAccounts() {
    return this._legalAccounts.value;
  }


  /**
   * Investment products with latest rates
   */
  private _products = new BehaviorSubject<Rate[]>([]);
  products$ = this._products.asObservable();

  set products(products: Rate[]) {
    this._products.next(products)
  }

  get products() {
    return this._products.value;
  }

  /**
   * Tier Rates
   */
  private _tierRates = new BehaviorSubject<Dictionary<Rate[]>>({});
  tierRates$ = this._tierRates.asObservable();

  set tierRates(rates: Dictionary<Rate[]>) {
    this._tierRates.next(rates);
  }
  get tierRates() {
    return this._tierRates.value;
  }

  /**
   * Currently Selected LegalAccount
   * Set a legalAccount object with the mininum of an ID property, so that the GET
   * will always return the latest version of the LegalAccount from the collection
   */
  private _selectedLegalAccount = new BehaviorSubject<LegalAccount>({} as LegalAccounts);
  selectedLegalAccount$ = this._selectedLegalAccount.asObservable();

  set selectedLegalAccount(legalAccount: LegalAccounts) {
    this._selectedLegalAccount.next(legalAccount)
  }
  // return the latest version of the selected LegalAccount from the LegalAccounts
  // based on the ID of the selectedLegalAccount. if SelectedLegalAccount is empty, use
  // the first legalAccount of the collection
  get selectedLegalAccount() {
    const legalAccount = !this._selectedLegalAccount.value ?
      this._legalAccounts.value[0] : this._selectedLegalAccount.value;

    return this._legalAccounts.value
      .filter(accounts => accounts.id === legalAccount.id)[0];
  }


  /**
   * Currently Selected LegalAccount
   * Set a legalAccount object with the mininum of an ID property, so that the GET
   * will always return the latest version of the LegalAccount from the collection
  */
  private _selectedCurrency = new BehaviorSubject<Currency>(Currency.AUD);
  selectedCurrency$ = this._selectedCurrency.asObservable();

  set selectedCurrency(currency: Currency) {
    this._selectedCurrency.next(currency)
  }

  get selectedCurrency() {
    return this._selectedCurrency.value ? this._selectedCurrency.value : Currency.AUD;
  }

  /**
   * Unfinished Application State management
   */
  private _applications = new BehaviorSubject<Application[]>([]);
  applications$ = this._applications.asObservable();

  set applications(applications: Application[]) {
    this._applications.next(applications);
  }
  get applications() {
    return this._applications.value;;
  }

  /**
   * Notifications
   */
  private _notifications = new BehaviorSubject<Notification[]>([]);
  notifications$ = this._notifications.asObservable();

  set notifications(notifications: Notification[]) {
    this._notifications.next(notifications);
  }

  get notifications() {
    return this._notifications.value;;
  }

  /**
   * Statements
   */

  private _statements = new BehaviorSubject<Dictionary<StatementReport[]>>({});
  statements$ = this._statements.asObservable();

  set statements(statements: Dictionary<StatementReport[]>) {
    this._statements.next(statements);
  }

  get statements() {
    return this._statements.value;;
  }

  getSelectedLegalAccountStatements() {
    const collection = this._statements.value;
    return collection[this.selectedLegalAccount.id];
  }

  /**
   * Currencies
   */
  private _currencies = new BehaviorSubject<Dictionary<Currency[]>>({});
  currencies$ = this._currencies.asObservable();

  set currencies(currencies: Dictionary<Currency[]>) {
    this._currencies.next(currencies);
  }
  get currencies() {
    return this._currencies.value;;
  }

  /**
   * Investments
   */
  private _investments = new BehaviorSubject<Dictionary<Investment[]>>({});
  investments$ = this._investments.asObservable();

  set investments(investments: Dictionary<Investment[]>) {
    this._investments.next(investments);
  }
  get investments() {
    return this._investments.value;;
  }

  getSelectedLegalAccountCurrencies() {
    const collection = this._currencies.value;
    return collection[this.selectedLegalAccount.id];
  }

  addInvestment(investment: Investment) {
    const collection = this._investments.value;
    const existingInvestments = collection[investment.legalAccountId];

    const hasInvestment = existingInvestments.find(inv => inv.id === investment.id);

    if (!hasInvestment) {
      existingInvestments.push(investment)
      collection[investment.legalAccountId] = existingInvestments
      this._investments.next(collection);
    }
  }

  getSelectedLegalAccountInvestments() {
    const collection = this._investments.value;
    return collection[this.selectedLegalAccount.id];
  }

  /**
   * Liquidity
   */
  private _liquidity = new BehaviorSubject<Dictionary<LegalAccountLiquidity>>({});
  liquidity$ = this._liquidity.asObservable();

  set liquidity(liquidity: Dictionary<LegalAccountLiquidity>) {
    this._liquidity.next(liquidity);
  }

  get liquidity() {
    return this._liquidity.value;
  }

  /**
   * Current user
   */
  private _currentUser = new BehaviorSubject<User>({} as User);
  currentUser$ = this._currentUser.asObservable();

  set currentUser(currentUser: User) {
    this._currentUser.next(currentUser);
  }
  get currentUser() {
    return this._currentUser.value;
  }

  /**
   * MFA Verification for sensitive pages
   */
  private _mfaVerification2: Dictionary<any> = {};
  set mfaVerification2(data: MfaVerification2 | undefined) {
    if (data) {
      const [route, module] = Object.entries(data)[0];
      this._mfaVerification2[route] = {
        expires: (new Date().getTime() + 300000),
        ...module
      };

    }
    //  else {
    //  this._mfaVerification2 = undefined;
    //  }
  }
  get mfaVerification2(): MfaVerification2 | undefined {
    const now = new Date().getTime();
    for (const [route, module] of Object.entries(this._mfaVerification2)) {
      this._mfaVerification2[route] = {
        ...module,
        verified: module.expires && module.expires > now || false
      }
    }
    return this._mfaVerification2;
    // if (this._mfaVerification2) {
    //   const now = new Date().getTime();
    //   return {
    //     ...this._mfaVerification,
    //     verified: this._mfaVerification.expires && this._mfaVerification.expires > now || false
    //   }
    // } else {
    //   return {
    //     verified: false
    //   };
    // }
  }

  private _mfaVerification: MfaVerification | undefined;
  set mfaVerification(data: MfaVerification | undefined) {
    if (data) {
      this._mfaVerification = {
        expires: (new Date().getTime() + 300000),
        ...data
      }
    } else {
      this._mfaVerification = undefined;
    }
  }
  get mfaVerification(): MfaVerification | undefined {
    if (this._mfaVerification) {
      const now = new Date().getTime();
      return {
        ...this._mfaVerification,
        verified: this._mfaVerification.expires && this._mfaVerification.expires > now || false
      }
    } else {
      return {
        verified: false
      };
    }
  }

  /**
   * Cache individual financial summary
   */
  private _financialSummary = new BehaviorSubject<FinancialSummary[]>(null);
  financialSummary$ = this._financialSummary.asObservable();

  set financialSummary(data: FinancialSummary[]) {
    this._financialSummary.next(data);
  }
  get financialSummary() {
    return this._financialSummary.value;
  }

  /**
   * Allows us to keep track of browser refresh
   */
  browserRefreshed?: boolean;

  /**
   * Splash screen variables
   */
  splashScreenOpen = false;

  constructor(
  ) {
    // this.router.events
    // .pipe(
    //    filter((event) => event instanceof NavigationEnd),
    //    startWith(this.router)
    // )
    // .subscribe((event: NavigationEnd) => {
    // there will be first router.url - and next - router events
    // })
  }

  /**
   * On logout we need to clear the state in case browsers are not refresh, flushing the cache
   */
  clearState() {
    this._legalAccounts.complete();
    this._selectedLegalAccount.complete();
    this._applications.complete();
    this._currentUser.complete();
    this.graphqlSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * When someone logs out we complete all the Observables. If the user logs in again without
   * refreshing the browsers, reinstate the Observables.
   */
  initState() {
    this.clearState();
    this._legalAccounts = new BehaviorSubject<LegalAccounts[]>([]);
    this.legalAccounts$ = this._legalAccounts.asObservable();

    this._selectedLegalAccount = new BehaviorSubject<LegalAccount>({} as LegalAccount);
    this.selectedLegalAccount$ = this._selectedLegalAccount.asObservable();

    this._applications = new BehaviorSubject<Application[]>([]);
    this.applications$ = this._applications.asObservable();

    this._currentUser = new BehaviorSubject<User>({} as User);
    this.currentUser$ = this._currentUser.asObservable();
  }

  setLegalAccounts(legalAccounts: LegalAccounts[]) {
    this.legalAccounts = legalAccounts;
    // set the first legalAccount as the default selected
    this.selectedLegalAccount = legalAccounts[0];
  }
}
