<mat-sidenav-container fxLayout="column" fxFlex (backdropClick)="sidenav.close()">
  <mat-sidenav #sidenav [opened]="layout.opened" [mode]="layout.mode" disableClose>
    <app-sidenav [currentUser]="currentUser$ | async" (toggleSideNav)="isMobile && sidenav.toggle()"
      [isMobile]="isMobile" [applicationUrl]="applicationUrl$ | async" fxFlex>
    </app-sidenav>
  </mat-sidenav>

  <mat-sidenav-content fxLayout="column" fxFlex [ngClass]="{'is-mobile': isMobile}">
    <ng-container *ngIf="toolbarConfig$ | async as toolbarConfig">
      <app-toolbar *ngIf="!isMobile" (selectedLegalAccount)="selectAccount($event)"
        [currentLegalAccount]="selectedAccount" [notifications]="notifications$ | async"
        [toolbarConfig]="toolbarConfig.desktop" [applicationUrl]="applicationUrl$ | async" [navBarTitle]="navBarTitle"
        [currentCurrency]="selectedCurrency" [currencies]="currencies" (selectedCurrency)="selectCurrency($event)"
        [accounts]="accounts$ | async" [showMultipleCurrenciesTab]="showMultipleCurrenciesTab">
      </app-toolbar>

      <app-toolbar-mobile *ngIf="isMobile" [currentUser]="currentUser$ | async" [currentLegalAccount]="selectedAccount"
        (selectedLegalAccount)="selectAccount($event)" (toggleSideMenu)="sidenav.toggle()"
        [toolbarConfig]="toolbarConfig.mobile" [notifications]="notifications$ | async"
        [mobileNavBarTitle]="mobileNavBarTitle" [accounts]="accounts$ | async" [currentCurrency]="selectedCurrency"
        [currencies]="currencies" (selectedCurrency)="selectCurrency($event)"
        [showMultipleCurrenciesTab]="showMultipleCurrenciesTab">
      </app-toolbar-mobile>
    </ng-container>
    <router-outlet></router-outlet>
    <app-bottom-nav-mobile *ngIf="isMobile && !hideMobileBottomBar"></app-bottom-nav-mobile>
  </mat-sidenav-content>

</mat-sidenav-container>

<app-show-welcome-dialog></app-show-welcome-dialog>

<!-- Preloading SVGs for Mat-icon so it doesn't generate a loading delay. -->
<app-svg-preload></app-svg-preload>