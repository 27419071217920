import { Component, OnInit } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { NavItem } from 'src/app/core/model/global';
import { getDevice } from '../../utils/isNativeApp';

@Component({
  selector: 'app-bottom-nav-mobile',
  templateUrl: './bottom-nav-mobile.component.html',
  styleUrls: ['./bottom-nav-mobile.component.scss']
})
export class BottomNavMobileComponent implements OnInit {

  navItems: NavItem[] = [];
  isMobileApp = getDevice().isMobileApp;
  isNativeApp = getDevice().isNativeApp;
  isIOSApp = getDevice().isIOSApp;

  constructor() {
  }

  ngOnInit(): void {
    this.navItems = [
      { type: 'item', svgIcon: 'earnr_dashboard', label: _('GLOBAL.BOTTOM_NAV.HOME'), routerLink: '', class: 'material-icons-outlined' },
      { type: 'item', svgIcon: 'earnr_summary_mob', label: _('GLOBAL.BOTTOM_NAV.ACCOUNTS'), routerLink: 'earnr', class: 'material-icons-outlined' },
      { type: 'item', svgIcon: 'earnr_transfers', label: _('GLOBAL.BOTTOM_NAV.TRANSFERS'), routerLink: 'transfer-or-add-funds', class: 'material-icons-outlined' },
      { type: 'item', svgIcon: 'earnr_profile', label: _('GLOBAL.BOTTOM_NAV.MORE'), routerLink: 'settings', class: 'material-icons-outlined' },
    ];
  }
}
