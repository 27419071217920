import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { LegalAccountResolverGuard } from './core/guards/legalAccount/legalAccountResolver.guard';
import { CurrentUserResolver } from './core/resolvers/currentUser';
import { FinancialSummaryResolver } from './core/resolvers/financialSummary.resolver';
import { InvestmentResolver } from './core/resolvers/investment.resolver';
import { NotificationResolver } from './core/resolvers/notification.resolver';
import { ProductsResolver } from './core/resolvers/products.resolver';
import { AuthenticatedLayoutComponent } from './shared/components/authenticated-layout/authenticated-layout.component';
import { PageContainerComponent } from './shared/components/page-container/page-container.component';
import { getDevice } from './shared/utils/isNativeApp';
import { StatementsResolver } from './core/resolvers/statements.resolver';
import { LiquidityResolver } from './core/resolvers/liquidity.resolver';

const routes: Routes = [
  {
    path: 'auth',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'finalise',
    loadChildren: () => import('./modules/finalise/finalise.module').then(m => m.FinaliseModule)
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./modules/verify-email/verify-email.module').then(m => m.VerifyEmailModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/error/error.module').then(m => m.ErrorModule)
  },
  // AUTHENTICATED VIEW
  {
    path: '',
    component: AuthenticatedLayoutComponent,
    canActivate: [
      AuthGuard,
      // this is actually acting like a resolver but it needs to be blocking so that the routing
      // guard below can work with the data received. Using a resolved does not work, the guard
      // does now wait for that.
      LegalAccountResolverGuard,
      // NotificationGuard,
    ],
    resolve: {
      currentUser: CurrentUserResolver,
      products: ProductsResolver,
      financialSummary: FinancialSummaryResolver,

    },
    children: [
      // this extra PageContainer allows us to use Guards on the resolved data of the parent root
      // before loading the child routes
      {
        path: '',
        component: PageContainerComponent,
        resolve: {
          investments: InvestmentResolver,
          liquidity: LiquidityResolver,
          notifications: NotificationResolver,
          statements: StatementsResolver
        },
        children: [
          {
            path: '',
            canActivateChild: [AuthGuard],
            data: {
              title: _('GENERAL.ROUTES.DASHBOARD'),
              toolbarConfig: {
                mobile: ['notifications', 'largeLogo', 'rightSpacer'],
              }
            },
            loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
          },
          {
            path: 'earnr',
            canActivateChild: [AuthGuard],
            data: {
              title: _('GENERAL.ROUTES.DASHBOARD_EARNR'),
              toolbarConfig: {
                mobile: ['navBack', 'accounts', 'rightSpacer'],
                desktop: ['accounts'],
              },
            },
            loadChildren: () => import('./modules/earnr/earnr.module').then(m => m.EarnrModule)
          },
          {
            path: 'add-to-earnr',
            canActivate: [AuthGuard],
            data: {
              title: _('GENERAL.ROUTES.ADD_TO_EARNR'),
              toolbarConfig: {
                mobile: ['leftSpacer', 'mobileNavBarTitle', 'smallLogo']
              }
            },
            loadChildren: () => import('./modules/add-to-earnr/add-to-earnr.module').then(m => m.AddToEarnrModule)
          },
          {
            path: 'transfer-or-add-funds',
            canActivate: [AuthGuard],
            data: {
              title: _('GENERAL.ROUTES.TRANSFER_OR_ADD_FUNDS'),
              toolbarConfig: {
                mobile: ['leftSpacer', 'mobileNavBarTitle', 'smallLogo']
              }
            },
            loadChildren: () => import('./modules/transfer-or-add-funds/transfer-or-add-funds.module').then(m => m.TransferOrAddFundsModule)
          },
          {
            path: 'open-new-legal-account',
            canActivate: [AuthGuard],
            data: {
              title: _('GENERAL.ROUTES.OPEN_NEW_LEGAL_ACCOUNT'),
              toolbarConfig: {
                mobile: ['leftSpacer', 'mobileNavBarTitle', 'smallLogo']
              }
            },
            loadChildren: () => import('./modules/open-new-legal-account/open-new-legal-account.module').then(m => m.OpenNewLegalAccountModule)
          },
          {
            path: 'notifications',
            canActivate: [AuthGuard],
            data: {
              title: _('GENERAL.ROUTES.NOTIFICATIONS'),
              toolbarConfig: {
                mobile: ['leftSpacer', 'largeLogo', 'rightSpacer']
              }
            },
            loadChildren: () => import('./modules/notification/notification.module').then(m => m.NotificationModule)
          },
          {
            path: 'connect',
            canActivate: [AuthGuard],
            data: {
              title: _('GENERAL.ROUTES.CONNECT'),
              toolbarConfig: {
                mobile: ['navBack', 'smallLogo']
              }
            },
            loadChildren: () => import('./modules/connect/connect.module').then(m => m.ConnectModule)
          },
          {
            path: 'profile',
            canActivate: [AuthGuard],
            data: {
              title: _('GENERAL.ROUTES.PROFILE')
            },
            loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule)
          },
          {
            path: 'settings',
            canActivate: [AuthGuard],
            data: {
              title: _('GENERAL.ROUTES.SETTINGS')
            },
            loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule)
          },
          {
            path: 'account',
            canActivate: [AuthGuard],
            data: {
              title: _('GENERAL.ROUTES.ACCOUNT'),
              toolbarConfig: {
                mobile: ['navBack', 'accounts', 'rightSpacer'],
                desktop: ['accounts']
              }
            },
            loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
          },
          // { path: '**', redirectTo: 'auth/login', pathMatch: 'full' }
          { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
        ]
      }
    ]


    // children: [
    //   {
    //     path: 'dashboard',
    //     canActivateChild: [AuthGuard],
    //     data: {
    //       title: _('GENERAL.ROUTES.DASHBOARD')
    //     },
    //     loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
    //   },
    //   {
    //     path: 'earnr',
    //     canActivateChild: [AuthGuard],
    //     data: {
    //       title: _('GENERAL.ROUTES.DASHBOARD_EARNR')
    //     },
    //     loadChildren: () => import('./modules/earnr/earnr.module').then(m => m.EarnrModule)
    //   },
    //   {
    //     path: 'add-to-earnr',
    //     canActivate: [AuthGuard],
    //     data: {
    //       title: _('GENERAL.ROUTES.ADD_TO_EARNR')
    //     },
    //     loadChildren: () => import('./modules/add-to-earnr/add-to-earnr.module').then(m => m.AddToEarnrModule)
    //   },
    //   {
    //     path: 'notifications',
    //     canActivate: [AuthGuard],
    //     data: {
    //       title: _('GENERAL.ROUTES.NOTIFICATIONS')
    //     },
    //     loadChildren: () => import('./modules/notification/notification.module').then(m => m.NotificationModule)
    //   },
    //   {
    //     path: 'connect',
    //     canActivate: [AuthGuard],
    //     data: {
    //       title: _('GENERAL.ROUTES.CONNECT')
    //     },
    //     component: ComingSoonComponent
    //   },
    //   {
    //     path: 'profile',
    //     canActivate: [AuthGuard],
    //     data: {
    //       title: _('GENERAL.ROUTES.PROFILE')
    //     },
    //     loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule)
    //   },
    //   {
    //     path: 'account',
    //     canActivate: [AuthGuard],
    //     data: {
    //       title: _('GENERAL.ROUTES.ACCOUNT')
    //     },
    //     loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
    //   },
    //   // { path: '**', redirectTo: 'auth/login', pathMatch: 'full' }
    //   { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    // ]
  },
  { path: '**', redirectTo: 'auth/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private translate: TranslateService) {
    translate.setDefaultLang('en');
  }
}
