
import { Injectable } from '@angular/core';
import { Address, Dictionary, LegalAccounts, LinkedAccountPurpose, StatementReport } from '@earnr/earnr-shared/dist/models';
import { Observable, of } from 'rxjs';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { Application } from '../../model/application';
import { ApiService } from '../api/api.service';

import { InvestmentLiquidity } from '@earnr/earnr-shared/dist/controllers';
import { Company, Partnership, Trust } from '@earnr/earnr-shared/dist/models';
import { AuthorisationToken, AuthorisationTokenStatus, TransferToken, TransferTokenStatus } from '@earnr/earnr-shared/dist/models/userToken';
import { toTitleCase } from '@earnr/earnr-shared/dist/utils';
import { map, tap } from 'rxjs/operators';
import { AppStateService } from '../app-state/app-state.service';

export interface UpdateLegalAccountInput {
  legalAccountId: string;
  individuals?: Dictionary<string>,
  trust?: Trust;
  company?: Company;
  partnership?: Partnership;
  companyTfn?: string;
  partnershipTfn?: string;
  trustTfn?: string;

}
export interface LegalAccountLiquidity {
  id: string;
  name: string;
  externalId: string;
  liquidity: InvestmentLiquidity[];
}
export interface UpdateTransferTokenOutput {
  status: string
  message?: string
}
export interface UpdateAuthoriseTokenOutput {
  status: string
  message?: string
}
export interface GetStatementsInput {
  legalAccountId: string
}

export interface UpdateTransferTokenInput {
  tokenId: string
  status: TransferTokenStatus
}

export interface UpdateAuthoriseTokenInput {
  tokenId: string
  status: AuthorisationTokenStatus
}
export interface TransferTokensInput {
  byLegalAccount: boolean
}
@Injectable({
  providedIn: 'root'
})
export class LegalAccountService {

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private appState: AppStateService,
  ) { }

  refreshLegalAccounts() {
    return this.getLegalAccounts()
      .pipe(
        tap(legalAccounts => {
          console.log('RunningRefresh', legalAccounts)
          if (Array.isArray(legalAccounts) && legalAccounts.length) {
            this.appState.legalAccounts = legalAccounts;
          }
        })
      );
  }

  /**
   * Fetch all the LegalAccounts that included this Individual (Cognito User)
   */
  getLegalAccounts(individualId?: string): Observable<LegalAccounts[]> {
    const id = individualId || this.authService.currentAuthState.id;
    if (!id) {
      return of([]);
    }
    const statement = `query getLegalAccountsByIndividual($individualId: ID!) {
        getLegalAccountsByIndividual(individualId: $individualId) {
          id
          bpayId
          name,
          displayName,
          type,
          banks {
            id
            currency
            bsb
            fiMnemonic
            bsbName
            displayName
            number
          }
          status
          currencies
          interestSummary {
            financialYearInterest {
              financialYear
              interest
            }
            yearToDateInterest
            lifetimeInterest
          }
          investmentIds
          individuals {
            id
            firstName
            middleName
            lastName
            tfn
            verified
            loginHistory {
              lastLoggedIn
              userAgent
              platform
            }
            showWelcome
          }
          soleTrader {
            abn
            registeredAddress {
              fullAddress
            }
            postalAddress {
              fullAddress
            }
          }
          trust {
            abn
            name
            type
            tfn
          }
          company {
            name
            acn
            tfn
            registeredAddress {
              fullAddress
            }
            postalAddress {
              fullAddress
            }
          }
          partnership {
            abn
            tfn
            name
            registeredAddress {
              fullAddress
            }
            postalAddress {
              fullAddress
            }
          }
          primaryContact {
            id
            contactDetails {
              phone
              email
            }
          }
          status
        }
      }`;
    return this.apiService.graphql<LegalAccounts[]>(statement, {
      individualId: id,
    }, 'getLegalAccountsByIndividual')
      .pipe(
        map(accounts => {
          return accounts.map(account => ({
            ...account,
            name: toTitleCase(account.name.toLocaleLowerCase()),
            displayName: toTitleCase(account.displayName.toLocaleLowerCase()),
          }));
        })
      )
  }

  /**
   * Fetch all the statement for the provided input
   */
  getStatements(data: GetStatementsInput): Observable<StatementReport[]> {
    const input: Dictionary<any> = {
      ...data
    };
    const statements = `query getStatements($input: GetStatementsInput!) {
      getStatements(input: $input) {
        id
        legalAccountId
        legalAccountName
        type
        ... on AnnualStatement {
          financialYear
          holdingsAsAtDate
        }
        ... on HoldingStatement {
          holdingsAsAtDate
        }
        ... on MonthlyStatement {
          monthYear
          statementDate
        }
      }
    }`;

    return this.apiService.graphql<StatementReport[]>(statements, { input }, 'getStatements');
  }

  /**
   * Fetch all unfinished applications.
   * Ideally, there should be only on unfinshed application per Cognito User
   */
  getUnfinishedApplications(): Observable<Application[]> {
    const cognitoId = this.authService.currentAuthState.id;
    const statement = `query getUnfinishedApplications($cognitoId: ID!) {
      getUnfinishedApplications(cognitoId: $cognitoId) {
          id
          updatedAt
        }
      }`;
    return this.apiService.graphql<Application[]>(statement, {
      cognitoId,
    }, 'getUnfinishedApplications');
  }

  getYodleeToken() {
    const id = this.authService.currentAuthState.id;
    const statement = `query getYodleeToken($id: ID!) {
        getYodleeToken(id: $id)
      }`;
    return this.apiService.graphql<string[]>(statement, {
      id,
    }, 'getYodleeToken');
  }

  getTransferTokens(input: TransferTokensInput) {
    const statement = `query getTransferTokens($input: TransferTokensInput!) {
      getTransferTokens(input: $input) {
        id
        type
        processed
        accountId
        status
        authorisationTokenIds
        individualIds
        transferPayload {
          source {
            investmentId
            legalAccountName
            productName
          }
          destination {
            investmentId
            investmentTerm
            investmentProduct
            legalAccountName
            productName
          }
          amount
          requestedBy
          transferToBank
          name
        }
      }
    }`;

    return this.apiService.graphql<TransferToken[]>(statement, {
      input
    }, 'getTransferTokens');
  }

  updateTransferToken(data: UpdateTransferTokenInput) {

    const input: Dictionary<any> = {
      ...data
    };

    const statement = `mutation updateTransferToken($input: UpdateTransferTokenInput!) {
      updateTransferToken(input: $input) {
        status
        message
      }
    }`;

    return this.apiService.graphql<UpdateTransferTokenOutput>(statement, { input }, 'updateTransferToken');
  }

  getAuthorisationTokens() {
    const statement = `query getAuthorisationTokens {
      getAuthorisationTokens {
        id
        type
        processed
        accountId
        status
        transferTokenId
        transferToken {
          transferPayload {
            source {
              investmentId
              legalAccountName
              productName
            }
            destination {
              investmentId
              investmentTerm
              investmentProduct
              legalAccountName
              productName
            }
            amount
            requestedBy
            transferToBank
            name
          }
        }
        validated
      }
    }`;

    return this.apiService.graphql<(AuthorisationToken & { transferToken: TransferToken })[]>(statement, {
    }, 'getAuthorisationTokens');
  }

  getAuthoriseToken(id: string) {
    const statement = `query getAuthoriseToken($id: ID!) {
      getAuthoriseToken(id : $id) {
        id
        type
        processed
        accountId
        status
        transferToken {
          transferPayload {
            source {
              investmentId
              legalAccountName
              productName
            }
            destination {
              investmentId
              investmentTerm
              investmentProduct
              legalAccountName
              productName
            }
            amount
            requestedBy
            transferToBank
            name
          }
        }
        transferTokenId
        validated
      }
    }`;

    return this.apiService.graphql<AuthorisationToken & { transferToken: TransferToken }>(statement, {
      id
    }, 'getAuthoriseToken');
  }

  updateAuthoriseToken(data: UpdateAuthoriseTokenInput) {

    const input: Dictionary<any> = {
      ...data
    };

    const statement = `mutation updateAuthoriseToken($input: UpdateAuthoriseTokenInput!) {
      updateAuthoriseToken(input: $input) {
        status
        message
      }
    }`;

    return this.apiService.graphql<UpdateAuthoriseTokenOutput>(statement, { input }, 'updateAuthoriseToken');
  }

  /**
   * Update residential and/or postal address for the individual of the current Cognito session
   */
  updateAddress(data: { legalAccountId: string, businessType: string, registered: Address, postal: Address }): Observable<boolean> {
    const input: Dictionary<any> = {};
    if (Object.keys(data.registered).length) {
      input.registered = data.registered;
    }
    if (Object.keys(data.postal).length) {
      input.postal = data.postal;
    }
    input.businessType = data.businessType;
    input.legalAccountId = data.legalAccountId;

    const statement = `mutation updateLegalAccount($input: UpdateLegalAccountInput) {
        updateLegalAccount(input: $input)
      }`;
    return this.apiService.graphql<boolean>(statement, { input }, 'updateLegalAccount');
  }

  updateTfn(data: UpdateLegalAccountInput) {
    const input: Dictionary<any> = {
      ...data
    };
    const statement = `mutation updateLegalAccount($input: UpdateLegalAccountInput) {
      updateLegalAccount(input: $input)
    }`;
    return this.apiService.graphql<boolean>(statement, { input }, 'updateLegalAccount');
  }

  getLegalAccountLiquidity(legalAccountId: String): Observable<LegalAccountLiquidity> {

    const statement = `
        query getLegalAccountLiquidity($legalAccountId: ID!) {
          getLegalAccountLiquidity(legalAccountId: $legalAccountId) {
            id
            name
            externalId
            liquidity {
              investmentId
              externalId
              currentBalance
              product
              term
              productCode
              investmentCurrency
              availableAmount
              availableDate
              legalAccountName
              legalAccountId
            }
          }
        }
      `;

    return this.apiService.graphql<LegalAccountLiquidity>(statement, {
      legalAccountId
    }, 'getLegalAccountLiquidity');

  }

  updateLinkedBankAccount(data: {
    accountId?: string,
    legalAccountId: string,
    providerAccountId: number,
    providerId: number,
    providerName: string,
    individualId: string,
    purpose: LinkedAccountPurpose
  }) {
    const input: Dictionary<any> = {
      ...data
    };
    const statement = `mutation updateLinkedBankAccount($input: UpdateLinkedBankAccountInput) {
      updateLinkedBankAccount(input: $input)
    }`;
    return this.apiService.graphql<boolean>(statement, { input }, 'updateLinkedBankAccount');
  }


}
