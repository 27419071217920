<svg [attr.width]="width" [attr.height]="height" viewBox="0 0 110 21" fill="none" xmlns="http://www.w3.org/2000/svg" [ngClass]="{ 'white': isWhite, 'dark': !isWhite }">
  <path d="M3.22778 11.6394C3.22778 10.456 4.16293 9.49658 5.31649 9.49658C6.47004 9.49658 7.40519 10.456 7.40519 11.6394V16.2861C7.40519 17.4695 6.47004 18.4289 5.31649 18.4289C4.16293 18.4289 3.22778 17.4695 3.22778 16.2861V11.6394Z" fill="#3272E3"/>
  <path d="M3.22778 13.4256C3.22778 12.2421 4.16293 11.2827 5.31649 11.2827C6.47004 11.2827 7.40519 12.2421 7.40519 13.4256V16.2857C7.40519 17.4692 6.47004 18.4286 5.31649 18.4286C4.16293 18.4286 3.22778 17.4692 3.22778 16.2857V13.4256Z" fill="currentColor"/>
  <path d="M8.65845 8.39091C8.65845 7.20744 9.59359 6.24805 10.7472 6.24805C11.9007 6.24805 12.8359 7.20744 12.8359 8.3909V16.2856C12.8359 17.4691 11.9007 18.4285 10.7472 18.4285C9.59359 18.4285 8.65845 17.4691 8.65845 16.2856V8.39091Z" fill="#3272E3"/>
  <path d="M8.65845 11.8714C8.65845 10.6879 9.59359 9.72852 10.7472 9.72852C11.9007 9.72852 12.8359 10.6879 12.8359 11.8714V16.286C12.8359 17.4694 11.9007 18.4288 10.7472 18.4288C9.59359 18.4288 8.65845 17.4694 8.65845 16.286V11.8714Z" fill="currentColor"/>
  <path d="M14.0891 5.14285C14.0891 3.95939 15.0243 3 16.1778 3C17.3314 3 18.2665 3.95939 18.2665 5.14286V16.2857C18.2665 17.4692 17.3314 18.4286 16.1778 18.4286C15.0243 18.4286 14.0891 17.4692 14.0891 16.2857V5.14285Z" fill="#3272E3"/>
  <path d="M14.0891 10.2859C14.0891 9.10246 15.0243 8.14307 16.1778 8.14307C17.3314 8.14307 18.2665 9.10246 18.2665 10.2859V16.2859C18.2665 17.4694 17.3314 18.4288 16.1778 18.4288C15.0243 18.4288 14.0891 17.4694 14.0891 16.2859V10.2859Z" fill="currentColor"/>
  <path d="M31.1211 20.0813C35.2684 20.0813 38.6521 17.4712 39.2954 13.5224H35.1181C34.4781 15.4826 33.1948 16.553 31.2414 16.553C28.5066 16.553 26.8237 14.8962 26.7335 12.0429H39.4457C39.9866 5.90679 36.6508 1.85693 30.9709 1.85693C25.7718 1.85693 22.1655 5.5693 22.1655 10.9691C22.1655 16.461 25.7117 20.0813 31.1211 20.0813ZM26.8237 9.54302C27.0941 7.05788 28.747 5.13977 31.0911 5.13977C33.5253 5.13977 35.0881 7.21129 35.2684 9.54302H26.8237Z" fill="currentColor"/>
  <path d="M49.7849 20.1426C52.1891 20.1426 54.4129 18.8847 55.2544 16.8598V19.7131H59.8224V2.19442H55.2544V5.17045C54.3829 3.08416 52.1891 1.85693 49.7548 1.85693C45.4874 1.85693 42.1516 5.07841 42.1516 10.9691C42.1516 16.8598 45.5174 20.1426 49.7849 20.1426ZM51.0771 16.2462C48.5227 16.2462 46.7195 14.3133 46.7195 10.9691C46.7195 7.65559 48.5227 5.7227 51.0471 5.7227C53.6015 5.7227 55.4047 7.68627 55.4047 10.9691C55.4047 14.2826 53.6015 16.2462 51.0771 16.2462Z" fill="currentColor"/>
  <path d="M63.7658 19.7438H68.3338V11.46C68.3338 7.37946 70.7079 5.75339 74.1339 6.39768V1.97966C70.7079 1.58081 68.9348 3.20688 68.3338 4.83296V2.19442H63.7658V19.7438Z" fill="currentColor"/>
  <path d="M76.3561 19.7438H80.9241V10.785C80.9241 7.53287 81.9759 5.81475 84.6205 5.81475C87.175 5.81475 88.347 7.28742 88.347 10.5089V19.7438H92.915V10.0794C92.915 4.55684 90.8414 1.85693 86.2434 1.85693C83.6288 1.85693 81.7055 3.08416 80.9241 5.01705V2.19442H76.3561V19.7438Z" fill="currentColor"/>
  <path d="M96.4595 19.7438H101.028V11.46C101.028 7.37946 103.402 5.75339 106.828 6.39768V1.97966C103.402 1.58081 101.629 3.20688 101.028 4.83296V2.19442H96.4595V19.7438Z" fill="currentColor"/>
</svg>
