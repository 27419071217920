<div fxLayout="column" fxLayoutAlign="center center" *ngIf="isMobile$ | async as isMobile"
  [ngClass]="{ mobile: isMobile.mobile }" class="container mat-typography">
  <h1 mat-dialog-title>{{ 'WELCOME_DIALOG.HEADING' | translate : { firstName: firstName} }}</h1>

  <ng-container *ngIf="!showWelcome">
    <app-notification-list [isMobile]="isMobile.mobile" (action)="doAction($event)"
      [notifications]="notifications$ | async">
    </app-notification-list>
  </ng-container>

  <ng-container *ngIf="showWelcome">
    <ng-container *ngIf="hasInvestment">
      <h4>{{ 'WELCOME_DIALOG.SUB_HEADING' | translate }}</h4>
      <div class="list-container" fxLayout="row" fxLayoutAlign="center center">
        <div class="list" fxFlex="100" fxFlex.gt-md="60">

          <div fxLayout="row" class="row" fxLayoutAlign="space-between center">
            <mat-icon class="blue">check</mat-icon>
            <div fxFlex>{{ 'WELCOME_DIALOG.LIST.ACCOUNT_CREATED' | translate }}</div>
          </div>

          <div fxLayout="row" class="row" fxLayoutAlign="space-between center">
            <mat-icon class="blue">check</mat-icon>
            <div fxFlex>{{ 'WELCOME_DIALOG.LIST.INVESTMENT_CONFIRMED' | translate: { product: product } }}</div>
          </div>

          <div fxLayout="row" class="row" fxLayoutAlign="space-between center">
            <mat-icon svgIcon="earnr_transfer_funds">
            </mat-icon>
            <div fxFlex>{{ 'WELCOME_DIALOG.LIST.TRANSFER_FUNDS' | translate }}</div>

            <mat-icon mat-dialog-close class="blue button" routerLink="/earnr/transfer-instructions"
              [queryParams]="{ id: investmentId }">arrow_forward_ios</mat-icon>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!hasInvestment">
      <div class="list-container" fxLayout="row" fxLayoutAlign="center center">
        <div class="list" fxFlex="100" fxFlex.gt-md="60">

          <div fxLayout="row" class="row" fxLayoutAlign="space-between center">
            <mat-icon class="blue">check</mat-icon>
            <div fxFlex>{{ 'WELCOME_DIALOG.LIST.ACCOUNT_CREATED' | translate }}</div>
          </div>

          <div fxLayout="row" class="row" fxLayoutAlign="space-between center">
            <mat-icon svgIcon="earnr_add">
            </mat-icon>
            <div fxFlex>{{ 'WELCOME_DIALOG.LIST.ADD_FUNDS' | translate }}</div>

            <mat-icon mat-dialog-close class="blue button" routerLink="/add-to-earnr">arrow_forward_ios</mat-icon>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>


</div>