import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Dictionary, StatementReport } from "@earnr/earnr-shared";
import { Observable, forkJoin } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { AppStateService } from "../services/app-state/app-state.service";
import { LegalAccountService } from "../services/legalAccount/legalAccount.service";

@Injectable({ providedIn: 'root' })
export class StatementsResolver implements Resolve<Boolean> {

  constructor(private appState: AppStateService, private legalAccountService: LegalAccountService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.appState.legalAccounts$.pipe(
      switchMap(legalAccounts => {
        const statementsCollection$ = {} as Dictionary<Observable<StatementReport[]>>;

        legalAccounts.forEach(legalAccount => {
          const statements$ = this.legalAccountService.getStatements({
            legalAccountId: legalAccount.id
          });
          statementsCollection$[legalAccount.id] = statements$;
        })
        return forkJoin(statementsCollection$)
      }),
      tap(val => {
        this.appState.statements = val;
      }), map(_ => true));
  }
}