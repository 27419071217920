<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px">
  <h2 class="title">{{ 'EARNR_TRANSFER.AUTHORISE_DIALOG.HEADER' | translate }}</h2>
  <p class="body">{{ 'EARNR_TRANSFER.AUTHORISE_DIALOG.BODY' | translate }}</p>


  <div fxLayout="column" fxLayoutGap="32px" fxLayoutAlign="center center" class="earnr-code">

    <div class="earnr-code">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'EARNR_TRANSFER.AUTHORISE_DIALOG.SECURITY_CODE_PLACEHOLDER' | translate }}</mat-label>

        <input matInput type="tel" inputmode="decimal" name="verificationCode" tabindex="2"
          [formControl]="earnrCodeControl" maxlength="6" minlength="6">

      </mat-form-field>

      <mat-error *ngIf="authoriseRequestResult.error">{{ labels[authoriseRequestResult.code] |
        translate
        }}</mat-error>
    </div>

    <app-submit-button label="" (onClick)="submitToken()" [isDisabled]="!codeRequested" [isLoading]="fetchingCode">
      {{ labels.submitButton | translate }}
    </app-submit-button>

    <div fxLayout="row" fxLayoutAlign="space-between center" class="action-buttons">

      <button mat-button mat-dialog-close class="cancel-button" [disabled]="fetchingCode">
        {{ labels.cancelButton | translate }}
      </button>

      <button mat-button class="resend-button" (click)="requestCode()" [disabled]="fetchingCode">
        {{ labels.resendCodeButton | translate }}
      </button>

    </div>

  </div>

</div>