import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Individual } from '@earnr/earnr-shared/dist/models';
import dayjs from 'dayjs';
import { NavItem } from 'src/app/core/model/global';
import { awsConfig } from 'src/aws-exports';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  @Output() toggleSideNav = new EventEmitter();
  @Input()
  set currentUser(user: Individual) {
    // currentUser resolves async
    if (!Object.keys(user).length) { return; }
    this.username = user.contactDetails.email;

    if (user.firstName && user.lastName) {
      this.userInitials = `${user.firstName[0]}${user.lastName[0]}`;
    }

    if (user.firstName) {
      const currentHour = dayjs().hour()

      if (currentHour < 12) {
        this.username = `Good morning ${user.firstName}`
      }
      else if (currentHour >= 12 && currentHour <= 17) {
        this.username = `Good afternoon ${user.firstName}`
      }
      else {
        this.username = `Good evening ${user.firstName}`
      }
    }
  }
  @Input() isMobile: boolean;
  @Input() applicationUrl: string;
  navItems: NavItem[] = [];
  username!: string;
  userInitials!: string;
  disableAuthRep!: boolean;

  navigateToSignup() {
    window.location.href = this.applicationUrl
  }

  constructor(
  ) { }

  ngOnInit(): void {
    this.navItems = [
      { type: 'item', svgIcon: 'earnr_dashboard', label: _('GLOBAL.SIDENAV.DASHBOARD'), routerLink: '', class: 'material-icons-outlined' },
      { type: 'item', svgIcon: 'earnr_summary', label: _('GLOBAL.SIDENAV.ACCOUNTS'), routerLink: 'earnr', class: 'material-icons-outlined' },
      { type: 'item', svgIcon: 'earnr_connect', label: _('GLOBAL.SIDENAV.ADD_FUNDS_TO_EARNR'), routerLink: 'add-to-earnr', class: 'material-icons-outlined' },
      { type: 'item', svgIcon: 'earnr_transfers', label: _('GLOBAL.SIDENAV.TRANSFERS'), routerLink: 'transfer-or-add-funds', class: 'material-icons-outlined' },
    ];

    if (!awsConfig.featureToggles.disableYodlee) {
      this.navItems = [
        ...this.navItems,
        { type: 'item', svgIcon: 'earnr_connect', label: _('GLOBAL.SIDENAV.CONNECT'), routerLink: 'connect/new', class: 'material-icons-outlined' }
      ]
    }

    if (this.isMobile) {
      this.navItems = [
        ...this.navItems,
        { type: 'item', icon: 'settings_outline', label: _('GLOBAL.SIDENAV.ACCOUNT'), routerLink: 'account', class: 'material-icons-outlined' },
      ]
    }
    this.disableAuthRep = awsConfig.featureToggles.disableAuthRep
  }

  closeOnRouteChange() {
    this.toggleSideNav.emit('toggle');
  }
}
