<div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="8px">
  <mat-form-field fxFlex="20">
    <mat-select name="countryCode" [formControl]="codeControl" panelClass="international-phone">
      <mat-option [value]="preferredCountryCode">{{ preferredCountryCode }}</mat-option>
      <mat-optgroup label="--------------">
        <mat-option *ngFor="let number of internationalNumbers" [value]="number.code">
          {{ number.code }} ({{number.label}})
        </mat-option>
    </mat-optgroup>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ label }}</mat-label>
    <input matInput type="tel" [formControl]="phoneControl" [mask]="mobileMask" [tabindex]="tabindex">
    <mat-error *ngIf="phoneControl.invalid">{{ 'GLOBAL.ERRORS.PHONE' | translate  }}</mat-error>
  </mat-form-field>
</div>
