import { Injectable } from '@angular/core';
import { LegalAccounts, Notification } from '@earnr/earnr-shared/dist/models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { subscriptionAction } from '../../model/subscriptions';
import { ApiService } from '../api/api.service';
import { AppStateService } from '../app-state/app-state.service';

// export interface Notification {
//   id: string;
//   icon: {
//     svgIcon?: string;
//     matIcon?: string;
//   };
//   type: 'ACTION' | 'MESSAGE';
//   title: string;
//   message?: string;
//   link?: {
//     slug: string;
//     queryParams?: Dictionary<string>
//   };
// }

@Injectable({
  providedIn: 'root'
})
export class NotificationService {


  initialised = false;
  _notifactions = new BehaviorSubject<Notification[]>([]);
  notifications$ = this._notifactions.asObservable();

  set notifications(items: Notification[]) {
    this._notifactions.next(items);
  }

  get notifications() {
    return this._notifactions.value;
  }

  constructor(
    private appState: AppStateService,
    private apiService: ApiService,
  ) {

     /**
     * Start Subscription for investments
     */
    const statement = `subscription onNotificationUpdate {
      onNotificationUpdate {
        action
        data
        __canRead
      }
    }`;

    this.appState.graphqlSubscriptions = [this.apiService
      .subscription<{ data: Notification, action: subscriptionAction, owner: string }>(statement, 'onNotificationUpdate')
      .subscribe(payload => {
        console.log('Notifications update received', payload)
        let { action } = payload;
        const { data } = payload;

        // since we have a soft delete atm, treat deleted=true as action DELETE
        if (data.deleted) {
          action = 'DELETE';
        }
        let records = this.appState.notifications;
        if (action === 'DELETE') {
          records = records.filter(item => item.id !== data.id);
        } else if (action === 'INSERT') {
          records = [...records, data];
        } else {
          // Assuming we use optimistic UI principle when adding a record, we are
          // just overwriting it for update or insert
          records = records.map(item => {
            if (item.id === data.id) {
              item = { ...item, ...data };
            }
            return item;
          })

          const hasRecord = records.find(item => item.id === data.id);
          if (!hasRecord) {
            records.push(data)
          }

        }
        this.appState.notifications = records;
      })];

  }

  addNotification(notification: Notification[]) {
    this.notifications = [...this.notifications, ...notification];
  }

  getNotifications(): Observable<Notification[]> {
    const statement = `
      query getNotifications {
        getNotifications {
          id
          message
          title
          metaData
        }
      }
    `;
    return this.apiService.graphql<Notification[]>(statement, null , 'getNotifications');
  }

  deleteNotification(id: string): Observable<boolean> {
    const statement = `
      mutation deleteNotification($id: ID!) {
        deleteNotification(id: $id)
      }
    `;
    return this.apiService.graphql<boolean>(statement, { id } , 'deleteNotification');
  }
}
