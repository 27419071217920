import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Address } from '@earnr/earnr-shared/dist/models';
import { awsConfig } from './../../../../aws-exports';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

export interface AddressData {
  address_line_1: string;
  address_line_2: string;
  box_identifier: string;
  box_type: string;
  canonical_address: string;
  canonical_address_id: string;
  full_address: string;
  lga_name: string;
  lga_type_code: string;
  locality_name: string;
  postcode: string;
  state_territory: string;
  street: string;
  street_name: string;
  street_number_1: string;
  street_number_2: string;
  street_suffix: string;
  street_type: string;
  success: true
  unit_identifier: string;
  unit_type: string;
}

declare const AddressFinder: any;

@Component({
  selector: 'app-address-finder',
  templateUrl: './address-finder.component.html',
  styleUrls: ['./address-finder.component.scss']
})
export class AddressFinderComponent implements AfterViewInit, OnDestroy {

  @ViewChild('addressContainer') container!: ElementRef;
  @ViewChild('addressInput') input!: ElementRef;

  @Input() label: string;
  @Input() type: string;
  @Input() placeholder: string;
  @Input() currentAddress!: Address;
  @Input() appearance = 'outline';
  @Output() address = new EventEmitter<Address | any>();

  showManualEntry(type: string) {
    if (this.type !== type) { return; }
    this.address.emit({ address: false, type});
    this.input.nativeElement.value = '';
    this.widget.disable();
    this.widget.enable();
  }
  
  lookupSuccess = false;
  _addressCtrl = new UntypedFormControl(null, { 
    updateOn: 'change', 
    validators: [Validators.required, validateAddressEmpty(this)] 
  });
  widget: any;
  noAddressFound = false;

  constructor(
    private translateService: TranslateService,
  ) {
  }

  ngAfterViewInit() {
    console.log(this.type)
    if (this.currentAddress) {
      this._addressCtrl.setValue(this.currentAddress.fullAddress);
    }
    this.initScript();
  }

  ngOnDestroy() {
    document.getElementById('addressFinder')?.remove();
  }

  private initScript() {
    const script = document.createElement("script");
    script.src = environment.addressFinder.scriptUrl;
    script.async = true;
    script.id = 'addressFinder';
    script.onload = this.initAddressFinder.bind(this);
    document.body.appendChild(script);
  }

  private initAddressFinder() {

    this.widget = new AddressFinder.Widget(
      this.input.nativeElement,
      awsConfig.addressFinderApiKey,
      environment.addressFinder.country,
      {
        manual_style: true,
        container: this.container.nativeElement,
        address_params: {
          'gnaf': '1'
        },
        max_results: 5,
        byline: false,
      }
    );
      
    this.widget.on("results:empty", () => {
      this.noAddressFound = true;
      this.lookupSuccess = false;
      this._addressCtrl.updateValueAndValidity();
    });

    this.widget.on("results:update", () => {
      if (this.widget.output.children.length > 0) {
        this.noAddressFound = false;
      }
    });

    this.widget.on( 'result:select', (fullAddress: any, metaData: AddressData) => {
      this.lookupSuccess = true;
      this._addressCtrl.updateValueAndValidity();

      let streetNumber = metaData.street_number_1
      if (metaData.street_number_2) {
        streetNumber = `${streetNumber}-${metaData.street_number_2}`
      }
      this.address.emit({ address: {
        streetName: metaData.street_name,
        streetType: metaData.street_type,
        streetNumber: streetNumber,
        unitNumber: metaData.unit_identifier,
        suburb: metaData.locality_name,
        state: metaData.state_territory,
        postcode: metaData.postcode,
        fullAddress: metaData.full_address,
        country: 'AU'
      }, type: this.type });
    });
  }

  validate() {
    this._addressCtrl.markAsTouched();
  }

  valid() {
    return this._addressCtrl.valid;
  }

  required(status: boolean) {
    if (status) {
      this._addressCtrl.setValidators([Validators.required]);
    } else {
      this._addressCtrl.setValidators([]);
    }
  }

  getErrorMessages(control: AbstractControl): string {
    if (control.invalid) {
      const error = Object.keys(control.errors!)[0];
      switch (error) {

        case 'required':
          return _('GLOBAL.VALIDATION.REQUIRED');

        default:
          return '';
      }
    } else {
      return '';
    }
  }
}


function validateAddressEmpty(component: AddressFinderComponent): ValidatorFn {
  return (ctrl: AbstractControl): ValidationErrors | null => {
    return component.lookupSuccess ? null : {
      empty: true
    }
  }
}
