import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Auth } from 'aws-amplify';
import { Subject, from, of } from 'rxjs';
import { catchError, map, takeUntil, timeout } from 'rxjs/operators';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

interface AuthoriseRequest {
  error?: boolean;
  code?: string;
  state?: Subject<string>
}

@Component({
  selector: 'app-authorise-transfer-dialog',
  templateUrl: './authorise-transfer-dialog.component.html',
  styleUrls: ['./authorise-transfer-dialog.component.scss']
})
export class AuthoriseTransferDialogComponent implements OnInit, OnDestroy {

  fetchingCode = false;
  codeRequested = false;

  earnrCodeControl = new UntypedFormControl({ value: '', disabled: false}, {
    validators: [Validators.required, Validators.pattern(/^[0-9]\d*$/)]
  });

  authoriseRequestResult: AuthoriseRequest;

  labels = {
    submitButton: _('EARNR_TRANSFER.AUTHORISE_DIALOG.SUBMIT_BUTTON'),
    resendCodeButton: _('EARNR_TRANSFER.AUTHORISE_DIALOG.RESEND_CODE_BUTTON'),
    cancelButton: _('EARNR_TRANSFER.AUTHORISE_DIALOG.CANCEL_BUTTON'),
    CodeMismatchException: _('EARNR_TRANSFER.AUTHORISE_DIALOG.SECURITY_CODE_ERROR'),
    ExpiredCodeException: _('PROFILE.MFA.CODE_EXPIRED'),
    LimitExceededException: _('PROFILE.MFA.LIMIT_EXCEEDED'),
  }

  onDestroy = new Subject();

  constructor(
    public dialogRef: MatDialogRef<AuthoriseTransferDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AuthoriseRequest,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.requestCode()
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  /**
 * Request a phone verification token
 */
  requestCode() {
    this.authoriseRequestResult = this.data;
    this.fetchingCode = true;

    from(Auth.verifyCurrentUserAttribute('phone_number'))
      .pipe(
        takeUntil(this.onDestroy),
        timeout(500),
        catchError(error => {
          return of({
            error: true,
            code: error.code,
          });
        }),
      )
      .subscribe(result => {
        // some kind of rate limit error happened
        console.log('-> requet', result, typeof result !== 'string');
        // if (typeof result !== 'string') {

        // Undefined mean the call was
        if (result !== undefined) {
          this.authoriseRequestResult = { ...this.authoriseRequestResult, ...result as AuthoriseRequest};
        } else {
          this.authoriseRequestResult = {
            error: false,
            code: 'VerficationCodeSend',
            state: this.authoriseRequestResult.state
          }
        }

        if (this.authoriseRequestResult.code === 'LimitExceededException') {
          this.earnrCodeControl.disable()
        }
        else {
          this.earnrCodeControl.enable();
        }

        this.fetchingCode = false;
        this.codeRequested = true;
      });
  }

  submitToken() {
    if (!this.earnrCodeControl.valid) {
      return;
    }

    this.fetchingCode = true;

    from(Auth.verifyCurrentUserAttributeSubmit('phone_number', `${this.earnrCodeControl.value}`))
      .pipe(
        takeUntil(this.onDestroy),
        catchError(error => {
          return of({
            error: true,
            code: error.code,
          });
        }),
        map(result => {
          return {
            error: typeof result === 'string' ? false : result.code,
            code: typeof result === 'string' ? result : result.code
          };
        }))
      .subscribe(result => {
        this.authoriseRequestResult = { ...this.authoriseRequestResult, ...result as AuthoriseRequest };

        if (this.authoriseRequestResult.code === 'SUCCESS') {
          this.authoriseRequestResult.state.next('SUCCESS')
        } else if (this.authoriseRequestResult.error) {
          this.fetchingCode = false;
        }
        else {
          this.fetchingCode = false;
        }
      });
  }

}
