import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { BehaviorSubject, from, Observable, of, Subject, timer } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthState, ConfirmEmailUpdateResponse, RequestEmailUpdateResponse } from 'src/app/modules/auth/model/auth';

import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
// import { ConfirmSignUpOptions } from '@aws-amplify/auth/lib-esm/types/Auth';
import { DevicePlatform } from '@earnr/earnr-shared/dist/models/endpoint';
import { LoginHistory } from '@earnr/earnr-shared/dist/models/individual';
import { CognitoSignInResponse, IdTokenPayload } from 'src/app/core/model/cognito';
import { User } from 'src/app/core/model/user';
import { ApiService } from 'src/app/core/services/api/api.service';
import { AppStateService } from 'src/app/core/services/app-state/app-state.service';
import { IndividualService } from 'src/app/core/services/individual/individual.service';
import { getDevice } from 'src/app/shared/utils/isNativeApp';

const { isIOSApp, isAndroidApp } = getDevice()
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  initialAuthState: AuthState = {
    isLoggedIn: false,
  };

  // Cognito's min expiry of accesstoken is 300 seconds. It automatically requests a new token which messes up
  // our logout. So I set just under 5 minutes for now unless we can get rid of the refresh token
  // https://github.com/aws/aws-cdk/issues/13514
  private APPLICATION_TIMEOUT_TIME = 1000 * 260; // 4min and 20 sec min leaving 30 sec for the expiry dialog so we stay just under 300 sec
  private cognitoUser!: CognitoUser;
  private readonly _authState = new BehaviorSubject<AuthState>(this.initialAuthState);
  readonly authState$ = this._authState.asObservable();

  set authState(authState: AuthState) {
    this._authState.next(authState);
  }

  applicationTimeout = new Subject();
  applicationTimeout$ = this.applicationTimeout.asObservable()
    .pipe(
      switchMap(continueTimer => {
        if (continueTimer) {
          return timer(this.APPLICATION_TIMEOUT_TIME)
        } else {
          return of(false);
        }
      }),
      map((val) => {
        // show timeout dialog
        if (val !== false) {
          this._authState.next({
            ...this._authState.value,
            sessionExpiring: true,
          });
        } else {
          this.applicationTimeout.complete();
        }
      })
    ).subscribe();

  get currentAuthState() {
    return this._authState.value;
  }
  get currentSession() {
    return from(Auth.currentSession()).pipe(
      catchError(error => {
        return of(false);
      })
    );
  }

  constructor(
    private appState: AppStateService,
    private individualService: IndividualService,
    private apiService: ApiService,
  ) { }

  isLoggedIn(): Observable<AuthState | null> {

    return this.currentSession
      .pipe(
        map((val: CognitoUserSession | boolean) => {
          if (typeof (val) !== 'boolean') {
            const idTokenPayload = val.getIdToken().payload as IdTokenPayload;
            const authState = {
              ...this._authState.value,
              isLoggedIn: true,
              id: idTokenPayload['cognito:username'],
              email: idTokenPayload.email,
              emailVerified: idTokenPayload.email_verified,
              newEmail: idTokenPayload['custom:newEmail'],
              verification: idTokenPayload['custom:verification'] ? JSON.parse(idTokenPayload['custom:verification']) : undefined,
              phone: idTokenPayload.phone_number,
              phoneVerified: idTokenPayload.phone_number_verified,
            }
            // push the authstate because someone might have refreshed the browser and then the authstate
            // would be in its initial state
            this._authState.next(authState);
            return authState;
          } else {
            return this.initialAuthState;
          }
        })
      );
  }

  cancelEmailUpdate() {
    return from(Auth.currentAuthenticatedUser())
      .pipe(
        switchMap(user => {
          return Auth.updateUserAttributes(user, {
            'custom:newEmail': '',
            'custom:verification': ''
          })
        })
      );
  }

  register(user: User): Observable<string> {
    const statement = `mutation CognitoSignUp(
      $input: CognitoSignUpInput!
    ) {
      cognitoSignUp(input: $input)
    }`;
    return this.apiService.graphql<string>(statement, {
      input: {
        email: user.email,
        phone: user.phone,
        password: user.password
      }
    }, 'cognitoSignUp', true)
  }


  refreshAuthSession() {
    return from(Auth.currentAuthenticatedUser({
      bypassCache: true
    }))
      .pipe(
        tap(data => {
          const userAttributes = data.attributes;
          const authState = {
            ...this._authState.value,
            isLoggedIn: true,
            email: userAttributes.email,
            newEmail: userAttributes['custom:newEmail'],
            verification: userAttributes['custom:verification'] ? JSON.parse(userAttributes['custom:verification']) : undefined,
            emailVerified: userAttributes.email_verified,
            phoneVerified: userAttributes.phone_number_verified,
            phone: userAttributes.phone_number,
          };
          this.authState = authState;
          const newUser = { ...this.appState.currentUser };
          newUser.contactDetails.email = userAttributes.email;
          this.appState.currentUser = newUser;
        }),
        map(data => {
          return true;
        })
      );
  }

  confirmSignUp(code: string): Observable<any> {
    return from<Promise<any>>(Auth.confirmSignUp(this.cognitoUser.getUsername(), code))
      .pipe(
        catchError(() => of({} as any))
      );
  }

  login(user: User): Observable<AuthState> {
    user.username = user.username.toLowerCase();
    this.appState.initState();
    return from<Promise<CognitoSignInResponse>>(Auth.signIn(user.username, user.password))
      .pipe(
        tap(data => console.log(data)),
        switchMap(data => this.updateLastLogin(data)),
        map(data => {
          const { signInUserSession: { idToken: { payload } } } = data;
          const authState: AuthState = {
            isLoggedIn: true,
            id: payload['cognito:username'],
            email: payload.email,
            newEmail: payload['custom:newEmail'],
            verification: payload['custom:verification'] ? JSON.parse(payload['custom:verification']) : undefined,
            emailVerified: payload.email_verified,
            phoneVerified: payload.phone_number_verified,
            phone: payload.phone_number,
          }
          this._authState.next(authState);
          return authState;
        }),
        catchError<AuthState, Observable<AuthState>>((error) => {
          console.log(error);
          return of({
            ...this.initialAuthState,
            error: true,
            errorMessage: error.message
          });
        })
      );
  }

  logout(authState = this.initialAuthState) {
    return from(Auth.signOut()).pipe(
      map(val => {
        console.log('---Logged out---')
        this.applicationTimeout.next(false);
        this.applicationTimeout.complete();
        this.appState.clearState();
        this._authState.next(authState);
        return true;
      })
    );
  }

  // this function simply emmits a new value on in the applicationTimeout Observable which resets the timer
  extendApplicationTimeout() {
    // remove any expiring flags from autState
    const currentAuthState = this._authState.value;
    delete currentAuthState.sessionExpiring;
    delete currentAuthState.sessionExpired;
    this._authState.next(currentAuthState);
    // emit a new value to reset timer
    this.applicationTimeout.next(true);
  }

  /**
   * Test is email has already been registered
  */
  emailExists(email: string): Observable<boolean> {
    const statement = `query doesEmailExist($email: String!) {
      doesEmailExist(email: $email)
    }`;
    return this.apiService.graphql<boolean>(statement, { email }, 'doesEmailExist');
  }

  /**
   * Start an email update procedure which will send a verification code to the email address
  */
  requestEmailUpdate(email: string): Observable<RequestEmailUpdateResponse> {
    const statement = `query requestEmailUpdate($email: String!) {
      requestEmailUpdate(email: $email) {
          error
          status
        }
      }`;
    return this.apiService.graphql<RequestEmailUpdateResponse>(statement, { email }, 'requestEmailUpdate');
  }

  /**
   * Finalise the email update procedure by validating the code and updating the email in cognito
  */
  confirmEmailUpdate(code: string): Observable<ConfirmEmailUpdateResponse> {
    const statement = `mutation confirmEmailUpdate($code: String!) {
      confirmEmailUpdate(code: $code) {
          error
          status
        }
      }`;
    return this.apiService.graphql<ConfirmEmailUpdateResponse>(statement, { code }, 'confirmEmailUpdate');
  }

  /**
   * Request a password reset token
  */
  sendPasswordResetSMS(mobile: string): Observable<Boolean> {
    const statement = `mutation sendPasswordResetSMS($mobile: String!) {
      sendPasswordResetSMS(mobile: $mobile)
      }`;
    return this.apiService.graphql<Boolean>(statement, { mobile }, 'sendPasswordResetSMS', true);
  }


  /**
   * This funtion gets the lastLoggedIn array and checks if it exists.
   * If exists, it adds the current logged in time and removes the first index.
   * End result is that we always have a last logged in (index 0) and the current logged in (index 1)
   * The first date (index 0) will be displayed in the UI.
   */
  public updateLastLogin(data?: CognitoSignInResponse) {
    return this.individualService.getLastLoggedIn()
      .pipe(
        switchMap(user => {
          if (user.createdAt) {
            const loginHistory: LoginHistory[] = []
            if (!Array.isArray(user.loginHistory) || user.loginHistory.length === 1) {
              loginHistory.push({
                lastLoggedIn: 0,
                userAgent: window.navigator.userAgent,
                platform: isIOSApp ? DevicePlatform.IOS : isAndroidApp ? DevicePlatform.ANDROID : DevicePlatform.WEB
              }, {
                lastLoggedIn: new Date().getTime(),
                userAgent: window.navigator.userAgent,
                platform: isIOSApp ? DevicePlatform.IOS : isAndroidApp ? DevicePlatform.ANDROID : DevicePlatform.WEB
              });
            } else {
              loginHistory.push(...user.loginHistory);
              loginHistory.push({
                lastLoggedIn: new Date().getTime(),
                userAgent: window.navigator.userAgent,
                platform: isIOSApp ? DevicePlatform.IOS : isAndroidApp ? DevicePlatform.ANDROID : DevicePlatform.WEB
              });
              loginHistory.splice(0, 1);
            }
            return this.individualService.updateIndividual({ loginHistory })
              .pipe(
                map(res => data)
              )
          } else {
            return of(data);
          }
        })
      )
  }
}
