import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {

  get isWhite() {
    return this.color === 'white';
  }

  @Input() color?: 'white' | 'dark';
  @Input() height = 21;
  @Input() width = 110;
  
  constructor() { }

  ngOnInit(): void {
  }

}
