<nav fxLayout="row" fxLayoutAlign="space-between center">


  <ng-container *ngIf="showElements.leftSpacer">
    <div class="spacer"></div>
  </ng-container>

  <ng-container *ngIf="showElements.navBack">
    <mat-icon (click)="navBack()" class="navigate-back">arrow_back_ios</mat-icon>
  </ng-container>


  <ng-container *ngIf="showElements.notifications">
    <!-- Notification button -->
    <button mat-icon-button routerLink="notifications" class="notifications">
      <mat-icon [matBadge]="notificationCount" matBadgeColor="accent">notifications_none</mat-icon>
    </button>
  </ng-container>

  <ng-container *ngIf="showElements.largeLogo">
    <app-logo color="white"></app-logo>
  </ng-container>

  <ng-container *ngIf="showElements.mobileNavBarTitle">
    <div class="mobile-navbar-title">{{ mobileNavBarTitle }}</div>
  </ng-container>

  <ng-container *ngIf="showElements.accounts">
    <div *ngIf="accounts.length === 1" class="legal-account-name">
      {{toTitleCase(currentLegalAccount.displayName || currentLegalAccount.name)}}
    </div>
    <ng-container *ngIf="accounts.length > 1">
      <button class="legal-account-selector" mat-button [matMenuTriggerFor]="menu">
        <span class="value">
          {{toTitleCase(currentLegalAccount.displayName || currentLegalAccount.name)}}
        </span>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item *ngFor="let account of accounts"
          (click)="selectAccount(account)">{{toTitleCase(account.displayName || account.name)}}</button>
      </mat-menu>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="showElements.hamburger">
    <!-- Hamburger menu -->
    <button mat-icon-button (click)="doToggleSideMenu()">
      <mat-icon svgIcon="earnr_hamburger"></mat-icon>
    </button>
  </ng-container>


  <ng-container *ngIf="showElements.smallLogo">
    <button mat-icon-button routerLink="/">
      <mat-icon svgIcon="earnr_logo"></mat-icon>
    </button>
  </ng-container>

  <ng-container *ngIf="showElements.rightSpacer">
    <div class="spacer"></div>
  </ng-container>

  <ng-container *ngIf="showElements.shortRightSpacer">
    <div class="short-right-spacer"></div>
  </ng-container>



</nav>

<nav mat-tab-nav-bar *ngIf="showCurrencies && showMultipleCurrenciesTab">
  <a mat-tab-link *ngFor="let currency of currencies" (click)="selectCurrency(currency)"
    [active]="currentCurrency === currency">
    {{ currency }}
  </a>
</nav>