import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Currency, LegalAccount } from '@earnr/earnr-shared/dist/models';
import { toTitleCase } from '@earnr/earnr-shared/dist/utils';
import { TranslateService } from '@ngx-translate/core';
import { filter, startWith } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification/notification.service';


@Component({
  selector: 'app-toolbar',
  templateUrl: './app-toolbar.component.html',
  styleUrls: ['./app-toolbar.component.scss']
})
export class AppToolbarComponent {

  @Input() accounts: LegalAccount[];
  @Input() currencies: Currency[];
  @Input() currentLegalAccount: LegalAccount;
  @Input() currentCurrency: Currency;
  @Input() showMultipleCurrenciesTab: boolean;
  @Output() selectedLegalAccount = new EventEmitter<LegalAccount>();
  @Output() selectedCurrency = new EventEmitter<Currency>();
  // @Input()
  // set toolbarConfig(val: any) {
  //   console.log('desktop', val);
  // }
  @Input()
  set notifications(count: number) {
    this.notificationCount = count === 0 ? '' : `${count}`;
  }
  @Input() applicationUrl: string;
  @Input() navBarTitle: string;

  @ViewChild('accountActionsMenu') accountActionsMenuTrigger: MatMenuTrigger;
  @ViewChild('menu') accountActionsMenu: MatMenu;

  pageTitle: string;
  notificationCount: string;
  navigateBackUrl: string;
  showNavBack = false;
  showAccounts = false;
  showCurrencies = false;

  get accountBtnActive() {
    return this.router.url.match(/^\/account/);
  }

  get profileBtnActive() {
    return this.router.url.match(/^\/profile/);
  }

  get inboxBtnActive() {
    return this.router.url.match(/^\/notifications/);
  }

  get approvalsBtnActive() {
    return this.router.url.match(/^\/earnr\/approvals/);
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private notificationService: NotificationService,
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      startWith(this.router),
    ).subscribe(() => {
      this.routeAnalyser(this.activatedRoute.root);
      this.showNavBack = this.router.url.match(/\//g).length > 1;
    });
  }

  selectAccount(account: LegalAccount) {
    this.currentLegalAccount = account;
    this.selectedLegalAccount.emit(account);
  }

  selectCurrency(currency: Currency) {
    this.currentCurrency = currency;
    this.selectedCurrency.emit(currency);
  }

  private routeAnalyser(route: ActivatedRoute) {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return;
    }
    this.navigateBackUrl = '';
    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      const { navigateBackUrl, toolbarConfig } = child.snapshot.data;
      if (navigateBackUrl && routeURL) {
        this.navigateBackUrl = navigateBackUrl
      }
      // decide if we need to show accounts based on the toolbarConfig defined in the Route segment
      this.showAccounts = false;
      this.showCurrencies = false;
      if (toolbarConfig && toolbarConfig.desktop) {
        if (toolbarConfig.desktop.filter((val: string) => val === 'accounts').length === 1) {
          this.showAccounts = true;
        } else {
          this.showAccounts = false;
        }
        if (toolbarConfig.desktop.filter((val: string) => val === 'currencies').length === 1) {
          this.showCurrencies = true;
        }
        else {
          this.showCurrencies = false;
        }
      }
      this.routeAnalyser(child);
    }
  }

  openAccountActionsMenu() {
    this.accountActionsMenuTrigger.openMenu()
    this.accountActionsMenu.resetActiveItem()
  }

  toTitleCase(text: string) {
    return toTitleCase(text);
  }

  triggerAccountAction(route: string) {
    this.router.navigate([route])
  }

  navigateToSignup() {
    window.location.href = this.applicationUrl
  }

  navBack() {
    if (this.navigateBackUrl) {
      this.router.navigate([this.navigateBackUrl]);
    } else {
      window.history.back();
    }
  }
}
