// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  env: 'development',
  addressFinder: {
    scriptUrl: 'https://api.addressfinder.io/assets/v3/widget.js',
    country: 'AU',
  },
  showNotificationDialog: true,
  yodlee: {
    v4: {
      fastLinkURL: 'https://fl4.preprod.yodlee.com.au/authenticate/ANZDevexPreProd-123/fastlink/?channelAppName=anzdevexpreprod',
      cdn: 'https://cdn.yodlee.com/fastlink/v4/initialize.js',
    },
    v3: {
      fastLinkURL: 'https://development-node.yodlee.com.au/authenticate/ANZDevexPreProd-123/?channelAppName=anzdevexpreprod',
      cdn: 'https://cdn.yodlee.com/fastlink/v3/initialize.js',
    },
    enabledVersion: 'v4',
  },
  transferInstructions: {
    bpay: {
      billerCode: '415836',
    },
    eft: {
      accountName: 'Earnr',
      swift: 'ANZBAU3M',
      bsb: '013 006',
      address: '100 Queen St, Melbourne, Australia'
    },
    accounts: [
      {
        currency: 'AUD',
        accountNumber: '8383-94963',
      },
      {
        currency: 'USD',
        accountNumber: '907105USD00001',
      },
      {
        currency: 'GBP',
        accountNumber: '907105GBP00001',
      },
      {
        currency: 'EUR',
        accountNumber: '907105EUR00001',
      },
      {
        currency: 'AED',
        accountNumber: '907105AED00001',
      },
      {
        currency: 'HKD',
        accountNumber: '907105HKD00001',
      },
      {
        currency: 'SGD',
        accountNumber: '907105SGD00001',
      },
      {
        currency: 'NZD',
        accountNumber: '907105NZD00001',
      }
    ]
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
