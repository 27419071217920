import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {


  @Input() message!: string;

  get content() {
    return this.sanitizer.bypassSecurityTrustHtml(this.message);
  }

  constructor(
    public translateService: TranslateService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
  }

}
