import { Component, OnInit } from '@angular/core';
import svgIconsManifest from 'src/assets/images/icons/svg/manifest.json';

@Component({
  selector: 'app-svg-preload',
  templateUrl: './svg-preload.component.html',
  styleUrls: ['./svg-preload.component.scss']
})
export class SvgPreloadComponent {

  svgIcons: { name: string, value: string }[] = svgIconsManifest;



}
