import { UntypedFormControl } from '@angular/forms';

const PHONE_REGEX = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
// const MOBILE_REGEX = /^(?:\+?(\d\d)) ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
// don't allow 0 after internation part
const MOBILE_REGEX = /^(?:\+?(\d\d)) ?(?:\((?=.*\)))?([2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;

const AUS_MOBILE_REGEX = /^(04)\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/

export function validateMobile(ctrl: UntypedFormControl) {
  return MOBILE_REGEX.test(ctrl.value) ? null : {
    mobile: {
      valid: false
    }
  };
}
export function validatePhone(ctrl: UntypedFormControl) {
  return PHONE_REGEX.test(ctrl.value) ? null : {
    mobile: {
      valid: false
    }
  };
}
export function validateAusMobile(ctrl: UntypedFormControl) {
  return AUS_MOBILE_REGEX.test(ctrl.value) ? null : {
    mobile: {
      valid: false
    }
  };
}