<div fxLayout="column" fxLayoutAlign="start stretch" fxFlex>


  <div class="logo-container" fxLayout="column" fxLayoutAlign="center start">
    <app-logo color="white"></app-logo>
  </div>

  <div class="avatar" fxLayout="column" fxLayoutAlign="center center">
    <div>
      <div class="fab" fxLayout="column" fxLayoutAlign="center center">
        <span *ngIf="userInitials">{{ userInitials }}</span>
        <mat-icon *ngIf="!userInitials">person_outline</mat-icon>
      </div>
    </div>
    <div class="username">{{ username }}</div>
  </div>

  <mat-nav-list (click)="closeOnRouteChange()">
    <mat-list-item *ngFor="let navItem of navItems" routerLink="{{navItem.routerLink}}" routerLinkActive="active-link"
      [routerLinkActiveOptions]="{exact: true}" class="resting">
      <mat-icon [class]="navItem.class" *ngIf="navItem.svgIcon" [svgIcon]="navItem.svgIcon"></mat-icon>
      <mat-icon [class]="navItem.class" *ngIf="navItem.icon">{{ navItem.icon }}</mat-icon>
      <label>{{ navItem.label! | translate }}</label>
    </mat-list-item>

    <!-- <mat-list-item *ngIf="!disableAuthRep" (click)="navigateToSignup()" class="resting">
      <mat-icon class="material-icons-outlined" svgIcon="earnr_connect"></mat-icon>
      <label>{{ 'GLOBAL.SIDENAV.CREATE_NEW_ACCOUNT' | translate }}</label>
    </mat-list-item> -->

  </mat-nav-list>
  <!-- vertical spacer -->
  <div fxFlex></div>

  <div fxLayout="row" fxLayoutAlign="center center" class="logout" *ngIf="isMobile">
    <button routerLink="/auth/logout" mat-button>
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-icon>logout</mat-icon>
        <div>{{ 'GLOBAL.BUTTONS.LOGOUT' | translate }}</div>
      </div>
    </button>
  </div>
</div>