import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, startWith } from 'rxjs/operators';

export interface BreadCrumb {
  label: string;
  urlSegment: string;
  active?: boolean;
}

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss']
})
export class BreadCrumbComponent implements OnInit {

  breadCrumbs!: BreadCrumb[]
  constructor(
    private router: Router,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      startWith(this.router),
    ).subscribe(() => {
      this.createBreadCrumb();
    });
  }

  createBreadCrumb() {
    const pathTitle = new Set<BreadCrumb>();
    this.routeAnalyser(this.activatedRoute.root, pathTitle);

    let url = '';
    const pathSegments = [...pathTitle];
    this.breadCrumbs = pathSegments.map((item, idx) => {
      item.urlSegment = `${url}/${item.urlSegment}`;
      item.active = idx !== pathSegments.length - 1;
      url = item.urlSegment;
      return item;
    });
  }

  private routeAnalyser(route: ActivatedRoute, title: Set<BreadCrumb>) {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return;
    }
    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      const label = child.snapshot.data.title;
      if (label && routeURL) {
        const segment: BreadCrumb = {
          label: this.translate.instant(label),
          urlSegment: routeURL
        };
        title.add(segment);
      }
      this.routeAnalyser(child, title);
    }
  }
}
