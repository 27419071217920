import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { EarnrTableRow } from './model';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-investment-table',
  templateUrl: './investment-table.component.html',
  styleUrls: ['./investment-table.component.scss'],
})
export class InvestmentTableComponent {

  @Input() isMobile!: boolean;
  @Input()
  set data(data: { desktop: EarnrTableRow[], mobile: any[] }) {
    this.desktopData = data.desktop.map(data => this.calcTableColumnWidth(data));
    this.mobileData = data.mobile;
  }
  @Output() action = new EventEmitter();

  @ViewChild('investmentsMenu') investmentsMenuTrigger: MatMenuTrigger;

  desktopData: EarnrTableRow[];
  mobileData: any[];


  triggerAction(rowId: string, action: string, event?: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.action.emit({ rowId, action });
    this.investmentsMenuTrigger.closeMenu();
  }

  openInvestmentsMenu() {
    this.investmentsMenuTrigger.openMenu()
    this.investmentsMenuTrigger.focus('program')
  }

  private calcTableColumnWidth(data: EarnrTableRow) {
    const cellCount = data.cells.length - 1;
    const width = Math.round(100 / cellCount);
    data.cells = data.cells.map((cell, index) => {
      if (index < cellCount) {
        cell.width = `${width}%`;
      }
      return cell;
    })
    return data;
  }

}
