/**
 * This pipe allows you to use template string in notation in a string and have it evaluated in runtime.
 */
import { Pipe, PipeTransform } from '@angular/core';

export interface InterPolateParams {
  [key: string]: string | number;
}

@Pipe({
  name: 'interpolate',
  pure: true
})
export class InterpolatePipe implements PipeTransform {

  transform(value: string, params: InterPolateParams, ...args: any[]): string {
    const names = Object.keys(params);
    const vals = Object.values(params);
    const _interpolate = new Function(...names, `return \`${value}\`;`);
    return _interpolate(...vals);
  }
}
