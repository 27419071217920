/**
 * Currently not used. We use the GUARD to fetch the template or redirect to a Page Not Found page
 */

import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Rate } from '@earnr/earnr-shared/dist/models';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AppStateService } from 'src/app/core/services/app-state/app-state.service';

import { InvestmentService } from '../services/investment/investment.service';
import { Dictionary } from '../model/global';

@Injectable({ providedIn: 'root' })
export class ProductsResolver implements Resolve<Rate[] | Boolean> {

  constructor(
    private appState: AppStateService,
    private investmentService: InvestmentService,
  ) { }

  resolve(): Observable<Rate[] | Boolean> {

    const productCodes = ['EW', 'NOTICE_30'];

    const foreginCurrencyCodes = ['USD3M', 'USD6M', 'USD12M', 'HKD3M', 'HKD6M', 'HKD12M', 'AED3M', 'AED6M', 'AED12M', 'GBP3M', 'GBP6M', 'GBP12M', 'EUR3M', 'EUR6M', 'EUR12M', 'SGD3M', 'SGD6M', 'SGD12M'];

    return this.investmentService.getProductRates(['EY3M', 'EY6M', 'EY12M', ...productCodes, ...foreginCurrencyCodes, 'EY3M_A1', 'EY6M_A1', 'EY12M_A1'])
      .pipe(
        tap(products => this.appState.products = products),
        switchMap(() => forkJoin(
          productCodes.map(code => this.investmentService.getTierRates(code))
        )),
        map(tierRates => {
          const tierRatesCollection = {} as Dictionary<Rate[]>;
          productCodes.forEach((code, index) => {
            tierRatesCollection[code] = tierRates[index];
          });

          this.appState.tierRates = tierRatesCollection;
          return true;
        })
      )
  }
}
