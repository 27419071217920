import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Currency, Dictionary, Individual, LegalAccount } from '@earnr/earnr-shared/dist/models';
import { toTitleCase } from '@earnr/earnr-shared/dist/utils';
import { filter, startWith } from 'rxjs/operators';


@Component({
  selector: 'app-toolbar-mobile',
  templateUrl: './app-toolbar-mobile.component.html',
  styleUrls: ['./app-toolbar-mobile.component.scss'],
})
export class AppToolbarMobileComponent implements OnInit {

  @Input() currentUser: Individual;
  @Input() accounts: LegalAccount[];
  @Input() currencies: Currency[];
  @Input() currentLegalAccount: LegalAccount = {} as LegalAccount;
  @Input() currentCurrency: Currency;
  @Input() showMultipleCurrenciesTab: boolean;
  @Input() mobileNavBarTitle: string;
  @Output() selectedLegalAccount = new EventEmitter<LegalAccount>();
  @Output() toggleSideMenu = new EventEmitter<boolean>();
  @Output() selectedCurrency = new EventEmitter<Currency>();
  // the Router will send a congfiguration to decide which toolbar items are displayed for this route
  @Input()
  set toolbarConfig(items: string[]) {
    this.showElements = {};
    if (Array.isArray(items)) {
      items.forEach(item => {
        this.showElements[item] = true;
      })
    };
    console.log('mobile', this.showElements);
  }
  @Input()
  set notifications(count: number) {
    this.notificationCount = count === 0 ? '' : `${count}`;
  }
  notificationCount: string;
  showElements: Dictionary<boolean> = {};
  navigateBackUrl: string;

  showCurrencies = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ref: ChangeDetectorRef,
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      startWith(this.router),
    ).subscribe(() => {
      this.routeAnalyser(this.activatedRoute.root);
    });
  }

  ngOnInit(): void {
  }

  selectAccount(account: LegalAccount) {
    this.currentLegalAccount = account;
    this.selectedLegalAccount.emit(account);
  }

  selectCurrency(currency: Currency) {
    this.currentCurrency = currency;
    this.selectedCurrency.emit(currency);
  }

  doToggleSideMenu() {
    this.toggleSideMenu.emit(true);
  }

  navBack() {
    if (this.navigateBackUrl) {
      this.router.navigate([this.navigateBackUrl]);
    } else {
      window.history.back();
    }
  }

  toTitleCase(text: string) {
    return toTitleCase(text);
  }

  private routeAnalyser(route: ActivatedRoute) {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return;
    }
    this.navigateBackUrl = '';
    for (const child of children) {
      // const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      // console.log('routeURL', child.snapshot.url, routeURL)
      const { navigateBackUrl, toolbarConfig } = child.snapshot.data;
      if (navigateBackUrl) {
        this.navigateBackUrl = navigateBackUrl
      }

      this.showCurrencies = false;
      if (toolbarConfig && toolbarConfig.desktop) {
        if (toolbarConfig.desktop.filter((val: string) => val === 'currencies').length === 1) {
          this.showCurrencies = true;
        } else {
          this.showCurrencies = false;
        }
      }

      // decide if we need to show accounts based on the toolbarConfig defined in the Route segment
      this.routeAnalyser(child);
    }
  }
}
