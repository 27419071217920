import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { API, Auth } from 'aws-amplify';
import { AppModule } from './app/app.module';
import { ApiService } from './app/core/services/api/api.service';
import { getDevice } from './app/shared/utils/isNativeApp';
import { awsConfig } from './aws-exports';
import { environment } from './environments/environment';

API.configure(awsConfig);

const device = getDevice();
console.log('Device:', device);

const cookieStorage = {
  domain: device.isLocalHost ? 'localhost' : 'earnr.com.au',
  path: '/',
  secure: !device.isLocalHost,
  expires: 1,
};

Auth.configure({
  region: awsConfig.aws_project_region,
  userPoolId: awsConfig.aws_user_pools_id,
  userPoolWebClientId: device.isNativeApp ? awsConfig.aws_user_pools_mobile_client_id : awsConfig.aws_user_pools_web_client_id,
  identityPoolId: awsConfig.aws_cognito_identity_pool_id,
  cookieStorage: !device.isNativeApp ? cookieStorage : undefined
});

if (environment.production) {
  enableProdMode();
}

try {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
} catch (error: any) {
  const api = new ApiService();
  const message = `[MAIN.TS] ${error.message} \n ${error.stack}`;
  api.graphql(`query reportError($message: String!) {
    reportError(message: $message)
  }`, {
    message,
  }, 'reportError', true).subscribe();
  console.log(error);
}