/**
 * Fetching the investments ASYNC (not blocking). I make the resolver not wait but return a `true` right away.
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Dictionary } from '@earnr/earnr-shared/dist/models';
import { Observable, forkJoin, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AppStateService } from 'src/app/core/services/app-state/app-state.service';
import { LegalAccountLiquidity, LegalAccountService } from '../services/legalAccount/legalAccount.service';

@Injectable({ providedIn: 'root' })
export class LiquidityResolver implements Resolve<Boolean> {

  constructor(
    private appState: AppStateService,
    private legalAccountService: LegalAccountService,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Boolean> {
    this.appState.legalAccounts$
      .pipe(
        switchMap(legalAccounts => {
          const liquidityCollection$ = {} as Dictionary<Observable<LegalAccountLiquidity>>;
          legalAccounts.forEach(legalAccount => {
            const liquidity$ = this.legalAccountService.getLegalAccountLiquidity(legalAccount.id);
            liquidityCollection$[legalAccount.id] = liquidity$;
          })
          return forkJoin(liquidityCollection$)
        }),
        tap(val => {
          this.appState.liquidity = val;
        }),
      ).subscribe();
    return of(true);
  }
}
