import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Address } from '@earnr/earnr-shared/dist/models';
import { toTitleCase } from '@earnr/earnr-shared/dist/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { COUNTRY_NAMES } from 'src/assets/static/international-phone';

@Component({
  selector: 'app-manual-address',
  templateUrl: './manual-address.component.html',
  styleUrls: ['./manual-address.component.scss']
})
export class ManualAddressComponent implements OnInit {

  onDestroy = new Subject();
  get country() {
    return this.form.get('country');
  }
  get state() {
    return this.form.get('state');
  }
  get streetNumber() {
    return this.form.get('streetNumber');
  }
  get streetName() {
    return this.form.get('streetName');
  }
  get streetType() {
    return this.form.get('streetType');
  }
  get postcode() {
    return this.form.get('postcode');
  }
  get suburb() {
    return this.form.get('suburb');
  }

  preferredCountries = COUNTRY_NAMES
    .filter(item => ['AU', 'NZ'].includes(item.code))
    .map(val => ({ value: val.code, viewValue: val.label}));
  countries = COUNTRY_NAMES
    .filter(item => !['AU', 'NZ'].includes(item.code))
    .map(val => ({ value: val.code, viewValue: val.label}));
  states = [
    { value: 'ACT', viewValue: 'Australian Captial Territory' },
    { value: 'NSW', viewValue: 'New South Wales' },
    { value: 'NT', viewValue: 'Northern Territory' },
    { value: 'QLD', viewValue: 'Queensland' },
    { value: 'SA', viewValue: 'South Australia' },
    { value: 'VIC', viewValue: 'Victoria' },
    { value: 'TAS', viewValue: 'Tasmania' },
    { value: 'WA', viewValue: 'Western Australia' },
  ];
  form!: UntypedFormGroup;
  @Input() currentAddress!: Address;
  @Output() newAddress = new EventEmitter();

  constructor() {
    this.form = new UntypedFormGroup({
      unitNumber: new UntypedFormControl(null, Validators.pattern(/^[0-9]{1,5}$/)),
      streetNumber: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^[0-9A-Z]{1,5}$/)]),
      streetName: new UntypedFormControl(null, Validators.required),
      streetType: new UntypedFormControl(null),
      suburb: new UntypedFormControl(null, Validators.required),
      postcode: new UntypedFormControl(null, [Validators.required]),
      state: new UntypedFormControl(null, Validators.required),
      country: new UntypedFormControl(null, Validators.required),
    })
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }


  ngOnInit(): void {
    if (this.currentAddress && Object.keys(this.currentAddress).length) {
      this.form.patchValue(this.currentAddress);
    }

    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((val: Address) => {
        if (val.streetName) {
          this.streetName.setValue(toTitleCase(val.streetName), {
            onlySelf: true,
            emitEvent: false,
          });
        }

        if (val.streetType) {
          this.streetType.setValue(toTitleCase(val.streetType), {
            onlySelf: true,
            emitEvent: false,
          });
        }

        if (val.streetNumber) {
          this.streetNumber.setValue(val.streetNumber.toUpperCase(), {
            onlySelf: true,
            emitEvent: false,
          });
        }


        if (val.state) {
          this.state.setValue(val.state.toUpperCase(), {
            onlySelf: true,
            emitEvent: false,
          });
        }

        if (val.postcode) {
          this.postcode.setValue(val.postcode.toUpperCase(), {
            onlySelf: true,
            emitEvent: false,
          });
        }

        if (val.suburb) {
          this.suburb.setValue(toTitleCase(val.suburb), {
            onlySelf: true,
            emitEvent: false,
          });
        }

        const address: Address = this.form.getRawValue();
        let fullAddress = `${address.streetNumber} ${address.streetName}${address.streetType ? ' ' + address.streetType : ''}, ${address.suburb} ${address.state} ${address.postcode}`;
        if (address.unitNumber) {
          fullAddress = `${address.unitNumber}, ${fullAddress}`;
        }
        address.fullAddress = fullAddress;
        address.manual = true;

        if (address.country === 'AU') {
          this.postcode.setValidators([Validators.required, Validators.pattern(/^[0-9]{4}$/)]);
        } else {
          this.postcode.setValidators([Validators.required]);
        }
        this.postcode.updateValueAndValidity({
          onlySelf: true
        });
        this.form.valid ? this.newAddress.emit(address) : null
      });
  }

  validate() {
    this.form.markAllAsTouched();
  }

  valid() {
    return this.form.valid;
  }

  getErrorMessages(controlName: string): string {
    const control = this.form.get(controlName);
    if (control && control.invalid) {
      const error = Object.keys(control.errors!)[0];

      if (error === 'required') {
        return _('GLOBAL.VALIDATION.REQUIRED');
      } else {
        if (controlName === 'postcode') {
          if (this.country.value !== 'AU') {
            return '';
          }
          switch (error) {
            case 'minlength':
            case 'maxlength':
            case 'pattern':
              return _('PROFILE.ADDRESS.VALIDATIONS.POSTCODE_AU');
            default:
              return '';
          }
        } else if (controlName === 'unitNumber' || controlName === 'streetNumber') {
            switch (error) {
              case 'minlength':
              case 'maxlength':
              case 'pattern':
                return _('PROFILE.ADDRESS.VALIDATIONS.STREET_NUMBER_AU');
              default:
                return '';
            }
        } else {
          return '';
        }
      }
    } else {
      return '';
    }
  }
}
