/**
 * Signature component using https://github.com/szimek/signature_pad
 */
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { getSydneyDate } from '@earnr/earnr-shared/dist/utils';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, startWith, switchMap, takeUntil } from 'rxjs/operators';
import SignaturePad from 'signature_pad';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})
export class SignaturePadComponent implements OnInit, AfterViewInit {

  signaturePad: SignaturePad;
  signaturePadCopy: SignaturePad;
  onDestroy = new Subject();
  showClear = false;
  beginStroke = false;
  addedTimeStamp = false;

  @Input() color = '#000'
  @Output() data = new EventEmitter<string>();

  @ViewChild('canvas', { static: false }) canvas: ElementRef;
  @ViewChild('canvasCopy', { static: false }) canvasCopy: ElementRef;

  constructor(
    private layoutService: LayoutService,
  ) { }

  ngOnInit(): void {
    fromEvent(window, 'resize')
      .pipe(
        startWith({}),
        debounceTime(100),
        takeUntil(this.onDestroy),
        switchMap(event => {
          return this.layoutService.isMobile$;
        })
      )
      .subscribe((isMobile) => {
        this.resizeCanvas(isMobile);
      });
  }
  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvas.nativeElement);
    this.signaturePadCopy = new SignaturePad(this.canvasCopy.nativeElement);
    this.signaturePad.penColor = this.color;
    this.signaturePadCopy.penColor = this.color;
    
    this.canvasCopy.nativeElement.width = 500;
    this.canvasCopy.nativeElement.height = 300;
    const canvasCtx =  this.canvas.nativeElement.getContext('2d');
    const canvasCopyCtx =  this.canvasCopy.nativeElement.getContext('2d');
    
    this.signaturePad.addEventListener('beginStroke', () => {
      this.beginStroke = true;
    });
    this.signaturePad.addEventListener('endStroke', () => {
      this.beginStroke = false;
      const imgData = canvasCtx.getImageData(0,0, 500, 500);
      // make a copy and add date time stamp
      canvasCopyCtx.putImageData(imgData, 0 , 30)
      if (!this.addedTimeStamp) {
        canvasCopyCtx.fillStyle = '#000'
        const timeNow = getSydneyDate().format('D MMMM YYYY HH:mm:ss');
        canvasCopyCtx.fillText(timeNow, 15 , 15)
        this.addedTimeStamp = true;
      }

      const data = this.signaturePadCopy.toDataURL().split(';base64,')[1];
      this.data.emit(data);
      this.showClear = true;
    });

    this.canvas.nativeElement.addEventListener('mouseleave', () => {
      // if we are still drawing but go outside of the canvas, endStroke.
      if (this.beginStroke) {
        this.signaturePad.dispatchEvent(new Event('endStroke'));
      }
    });
  }

  resizeCanvas(isMobile: boolean) {
    this.layoutService.isMobile$
    let width = 0;
    let height = 120;
    const sectionEl = document.getElementsByTagName('section')[0];
    const articleEl = sectionEl.getElementsByTagName('article')[0];

    if (isMobile) {
      const padding = parseInt(window.getComputedStyle(articleEl, null)
        .getPropertyValue('padding-left').replace('px', ''), 10);
      width = sectionEl.offsetWidth - padding * 2;
    } else {
      const padding = parseInt(window.getComputedStyle(articleEl, null)
        .getPropertyValue('padding-left').replace('px', ''), 10);
      width = articleEl.offsetWidth - padding * 2;
      height = 180;
    }
    this.canvas.nativeElement.width = width;
    this.canvas.nativeElement.height = height;
    
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  clear() {
    this.signaturePad.clear();
    this.signaturePadCopy.clear();
    this.data.emit();
    this.addedTimeStamp = false;
    this.showClear = false;
  }

}
