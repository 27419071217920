import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NavigationStart, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppStateService } from './core/services/app-state/app-state.service';
import { SessionExpiryDialogComponent } from './modules/auth/components/session-expiry-dialog/session-expiry-dialog.component';
import { AuthService } from './modules/auth/services/auth.service';
import { getDevice } from './shared/utils/isNativeApp';
import { ConnectionState, CONNECTION_STATE_CHANGE } from '@aws-amplify/pubsub';
import { Hub } from 'aws-amplify'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  @HostBinding('class') classList: string;

  dialogRef!: MatDialogRef<SessionExpiryDialogComponent>;
  subscription: Subscription;
  onDestroy = new Subject();
  isAndroid = getDevice().isAndroidApp;
  iskeyboardOpen = false


  constructor(
    private authService: AuthService,
    private router: Router,
    private appState: AppStateService,
    public dialog: MatDialog,
  ) {

    const bodyTag = document.body;
    this.classList = this.isAndroid ? 'isAndroidApp': '';

    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.appState.browserRefreshed = !router.navigated;
      }
    });

    this.authService.authState$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(authState => {
        if (authState.sessionExpiring) {
          this.openSessionExpiryDialog();
        }
        if (authState.sessionExpired) {
          this.dialogRef.close();
        }
      });

    Hub.listen('api', (data: any) => {
      console.log('hub api', data)
      const { payload } = data;
      if (payload.event === CONNECTION_STATE_CHANGE) {
        const connectionState = payload.data.connectionState as ConnectionState;
        console.log(connectionState);
      }
    });
    if (this.isAndroid) {
      window.visualViewport.addEventListener('resize', this.keyboardListener)
    }
  }

  keyboardListener = () => {
    this.iskeyboardOpen =  window.visualViewport.height < window.screen.height * 0.9

    if (this.iskeyboardOpen) {
      if (!this.classList.includes('keyboardOpen')) {
        this.classList = this.classList.concat(' ').concat('keyboardOpen')
      }
    }
    else {
      if (this.classList.includes('keyboardOpen')) {
        this.classList = this.classList.replace('keyboardOpen', '')
      }
    }
  }

  ngOnDestroy() {
    if (this.isAndroid) {
      window.visualViewport.removeEventListener('resize', this.keyboardListener)
    }
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  openSessionExpiryDialog(): void {
    this.dialogRef = this.dialog.open(SessionExpiryDialogComponent, {
      // width: '450px',
    });

    this.dialogRef.afterClosed()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(result => {
        // if result `undefined` is emitted, someone closed on the backdrop or hit escape. We treat this
        // as a `remiainLoggedIn` but only if the session didn't expire
        if (result === undefined && !this.authService.currentAuthState.sessionExpired) {
          result = 'remainLoggedIn';
        }

        if (result === 'remainLoggedIn') {
          this.authService.extendApplicationTimeout();
        } else {
          this.router.navigate(['auth/logged-out'], { queryParams: { secureLogout: true } });
        }
      });
  }
}
