/**
 * Fetching the investments ASYNC (not blocking). I make the resolver not wait but return a `true` right away.
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Dictionary, Investment } from '@earnr/earnr-shared/dist/models';
import { Observable, forkJoin, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AppStateService } from 'src/app/core/services/app-state/app-state.service';
import { InvestmentService } from '../services/investment/investment.service';

@Injectable({ providedIn: 'root' })
export class InvestmentResolver implements Resolve<Boolean> {

  constructor(
    private appState: AppStateService,
    private investmentService: InvestmentService,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Boolean> {
    this.appState.legalAccounts$
      .pipe(
        switchMap(legalAccounts => {
          const investmentCollection$ = {} as Dictionary<Observable<Investment[]>>;

          legalAccounts.forEach(legalAccount => {
            const investments$ = this.investmentService.getInvestments(legalAccount.investmentIds);
            investmentCollection$[legalAccount.id] = investments$;

          });

          return forkJoin(investmentCollection$);
        }),
        tap(val => {
          this.appState.investments = val;
        }),
      ).subscribe();
    return of(true);
  }
}
