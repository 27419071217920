import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { getDevice } from 'src/app/shared/utils/isNativeApp';

const { isNativeApp, isMobileApp } = getDevice();

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


    // if (!isNativeApp && isMobileApp && state.url.search(/404/) === -1) {
    //   this.authService.logout();
    //   return this.router.parseUrl('/error/404');
    // }

    const bodyTag = document.body;
    bodyTag.setAttribute('page', state.url.replace(/^\//, '').replace(/\//g, '-'))

    return this.authService.isLoggedIn()
      .pipe(
        switchMap(authState => {
          if (authState && authState.isLoggedIn && isMobileApp && isNativeApp) {
            return this.authService.updateLastLogin().pipe(map(data => authState))
          }
          return of(authState)
        }),
        map(authState => {

          if (authState && authState.isLoggedIn && isMobileApp && !isNativeApp && state.url.search(/404/) === -1) {
            this.authService.logout();
            return this.router.parseUrl('/error/404');
          }

          // console.log('CanActivate', state.url, authState?.isLoggedIn);
          if (authState && authState.isLoggedIn && state.url.search(/auth/) === -1 && !isNativeApp) {
            // On every route reset application timer
            this.authService.extendApplicationTimeout();
          }

          if (authState && authState.isLoggedIn && state.url.search(/\auth\/login/) === 1) {
            if (!isNativeApp) {
              return this.router.parseUrl('/')
            }
            else {
              return this.router.parseUrl('/dashboard')
            }
          }

          // if NOT logged in and the route we want to go to is not part of auth
          if (authState && !authState.isLoggedIn && state.url.search(/auth/) === -1) {
            return this.router.parseUrl('/auth');
            // Auto logout if someone goes to the root
          } else if (authState && authState.isLoggedIn && state.url === '/') {
            // this.authService.logout();
            // return this.router.parseUrl('/auth');
          }
          return true;
        }));
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const bodyTag = document.body;
    bodyTag.setAttribute('page', state.url.replace(/^\//, '').replace(/\//g, '-'))
    return this.authService.isLoggedIn()
      .pipe(
        map(authState => {

          if (authState && authState.isLoggedIn && isMobileApp && !isNativeApp && state.url.search(/404/) === -1) {
            this.authService.logout();
            return this.router.parseUrl('/error/404');
          }

          // console.log('CanActivateChild', state.url, authState?.isLoggedIn, route.data);
          if (authState && authState.isLoggedIn && state.url.search(/auth/) === -1 && !isNativeApp) {
            this.authService.extendApplicationTimeout();
          }
          // if NOT logged in and the route we want to go to is not part of auth
          if (authState && !authState.isLoggedIn && state.url.search(/auth/) === -1) {
            return this.router.parseUrl('/auth');
          }
          return true;
        })
      );
  }
}
