import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Observable, of, timer } from 'rxjs';
import { map, takeWhile, switchMap } from 'rxjs/operators';
import { Dictionary } from 'src/app/core/model/global';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-session-expiry-dialog',
  templateUrl: './session-expiry-dialog.component.html',
  styleUrls: ['./session-expiry-dialog.component.scss']
})
export class SessionExpiryDialogComponent implements OnInit {
  counter = 30;
  counter$ = timer(0, 1000) //Initial delay 0 seconds and interval countdown also 1 second
    .pipe(
      takeWhile(() => this.counter >= 0),
      map(() => {
        return this.counter--;
      }),
      switchMap(counter => {
        if (counter === 0) {
          console.log('Session expiry logout triggered');
          return this.authService.logout({
            ...this.authService.initialAuthState,
            sessionExpired: true,
          });
        }
        return of(counter);
      })
    )
  constructor(
    public dialogRef: MatDialogRef<SessionExpiryDialogComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: Dictionary<any>,
  ) { }

  ngOnInit(): void {
  }

  session(action: 'remainLoggedIn' | 'logout') {
    if (action === 'logout') {
      this.authService.logout({
        ...this.authService.initialAuthState,
        sessionExpired: true,
      });
    }
    this.dialogRef.close(action);
  }
}
